import {
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Tabs,
} from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

import { CreateDocumentForm } from "@/components/organisms/CreateDocumentForm/CreateDocumentForm";
import { useScreenSize } from "@/hooks/use-screen-size";

import { paths } from "@/routes/paths";
import { ExpertDocuments } from "./ExpertDocuments";
import { ExpertIndustry } from "./ExpertIndustry";
import { ExpertPreferences } from "./ExpertPreferences";
import { ExpertProfile } from "./ExpertProfile";
import { ExpertSoftware } from "./ExpertSoftware";
import * as styles from "./Profile.module.css";

export function EditProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLg } = useScreenSize();

  const isDocumentTabActive = useMatch(`${paths.profile.documents.root}/*`);

  const pathToItProfile = paths.profile.root;
  const pathToDocuments = paths.profile.documents.root;

  return (
    <Container component="main">
      <Grid>
        <Grid.Col span={{ lg: location.pathname === pathToItProfile ? 12 : 8 }}>
          <Paper className={clsx(styles.paper, styles.mainContent)}>
            <Tabs
              component="nav"
              withUnderline={false}
              keepMounted={false}
              value={isDocumentTabActive ? pathToDocuments : location.pathname}
              onChange={(pathname) => navigate(pathname as string)}
            >
              <Tabs.List>
                <Tabs.Tab value={pathToItProfile}>IT profile</Tabs.Tab>
                <Tabs.Tab value={pathToDocuments}>Guides written</Tabs.Tab>
              </Tabs.List>
              <Divider className="-mx-6 mt-2 mb-6" />
              <Tabs.Panel value={pathToItProfile}>
                <ExpertProfile />
                <Divider className="-mx-6 my-6" />
                <ExpertSoftware />
                <Divider className="-mx-6 my-6" />
                <ExpertIndustry />
                <Divider className="-mx-6 my-6" />
                <ExpertPreferences />
              </Tabs.Panel>
              <Tabs.Panel value={pathToDocuments}>
                <Stack>
                  {isDocumentTabActive && !isLg && <CreateDocumentForm />}
                  <ExpertDocuments />
                </Stack>
              </Tabs.Panel>
            </Tabs>
          </Paper>
        </Grid.Col>
        {isDocumentTabActive && isLg && (
          <Grid.Col span={{ lg: 4 }}>
            <Paper component="aside" className={styles.paper}>
              <CreateDocumentForm />
            </Paper>
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
