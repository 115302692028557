import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";

export function getReadNotificationsUrl() {
  return "/notifications/read";
}

export function useSetNotificationsAsRead(execute: boolean) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return axios.post(getReadNotificationsUrl());
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notificationCount"] });
    },
  });

  useEffect(() => {
    if (execute) {
      mutation.mutate();
    }
  }, [execute, mutation.mutate]);
}
