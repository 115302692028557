import { Button, Group, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { SoftwareGrid } from "@/components/molecules/SoftwareGrid/SoftwareGrid";
import { SoftwareForm } from "@/components/organisms/SoftwareForm/SoftwareForm";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { useExpertSoftware } from "@/hooks/use-software";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";
import * as styles from "./Profile.module.css";

const ExpertSoftware = () => {
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("software"));
  const { data: softwares, refetch } = useExpertSoftware();

  if (!softwares) return null;

  const hasContents = !!softwares.length;
  const onClose = () => {
    handlers.close();
    // TODO: A better way to handle this is to invalidate the query cache
    // when ExpertSoftwareForm is submitted
    refetch();
  };

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={1} className={styles.title}>
          Software expertise
        </Title>

        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ExpertOwnProfileEvent.name,
              ExpertOwnProfileEvent.properties.editSoftware,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {hasContents && (
        <>
          <Text className="mb-4">
            You’ve added {softwares.length} application
            {softwares.length > 1 && "s"} to your expertise.
          </Text>
          <SoftwareGrid softwares={softwares} cols={4} />
        </>
      )}
      {!hasContents && (
        <Text className={styles.secondaryText}>
          Please add software you have expertise in. This information will help
          us request relevant documents and match you with potential clients.
        </Text>
      )}
      <Drawer
        opened={opened}
        onClose={onClose}
        title="Add your software expertise"
      >
        <SoftwareForm
          checked={softwares?.map((s) => s.id)}
          for="expert"
          onClose={onClose}
        />
      </Drawer>
    </>
  );
};

export { ExpertSoftware };
