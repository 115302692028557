import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { useNotifications } from "@/hooks/use-notifications";
import { useSetNotificationsAsRead } from "@/hooks/use-notifications-mutation";
import { Container, Paper, Text, Title } from "@flpstudio/design-system";
import { NotificationList } from "./NotificationList";

export function NotificationsView() {
  const { data, isLoading, isSuccess } = useNotifications();

  return (
    <Container component="main">
      <header className="mb-4">
        <Title className="font-semibold text-2xl">Notifications</Title>
      </header>
      <Notifications
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess && !isLoading}
      />
    </Container>
  );
}

export function Notifications({
  isLoading,
  isSuccess,
  data,
}: {
  isLoading: boolean;
  isSuccess: boolean;
  data: ReturnType<typeof useNotifications>["data"];
}) {
  useSetNotificationsAsRead(isSuccess);

  if (isLoading) {
    return <Skeleton repeat={3} />;
  }

  if (!data?.hasNotifications) {
    return (
      <Paper>
        <Text className="text-[--mantine-color-gray-6] text-sm">
          You don’t have any notifications yet. Notifications will be displayed
          when others interact with your documents.
        </Text>
      </Paper>
    );
  }

  if (data?.hasNotifications) {
    return (
      <Paper className="flex flex-col gap-6">
        <NotificationList
          notifications={data.notifications.today}
          title="Today"
        />
        <NotificationList
          notifications={data.notifications.lastSevenDays}
          title="Last 7 days"
        />
        <NotificationList
          notifications={data.notifications.older}
          title="Older"
        />
      </Paper>
    );
  }

  // todo: Error handling?
  return null;
}
