import { EditProfile as EditExpertProfile } from "@/components/organisms/EditExpertProfile/Profile";
import { useAuth } from "@/hooks/use-auth";
import { Profile as ClientProfile } from "./client/Profile";

export function Profile() {
  const { user } = useAuth();

  if (user?.clientProfile) {
    return <ClientProfile />;
  }

  if (user?.expertProfile) {
    return <EditExpertProfile />;
  }

  return null;
}
