import {
  Flex,
  NativeSelect,
  SimpleGrid,
  Stack,
  TextInput,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { useAutoTriggerAction } from "@/hooks/use-auto-trigger-action";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import { Amplitude, ClientOnboardingEvent } from "@/third-party/amplitude";
import { countryList } from "@/utils/country-list";
import type { User } from "types";
import { UserPhotoForm } from "../UserPhotoForm/UserPhotoForm";

const NAME_MAX_LENGTH = 50;
const CITY_MAX_LENGTH = 100;
const COMPANY_MAX_LENGTH = 100;
const JOB_TITLE_MAX_LENGTH = 50;

const schema = validator.object({
  firstName: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .max(
      NAME_MAX_LENGTH,
      `Name should be ${NAME_MAX_LENGTH} characters or less`,
    ),
  lastName: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .max(
      NAME_MAX_LENGTH,
      `Name should be ${NAME_MAX_LENGTH} characters or less`,
    ),
  company: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .max(
      COMPANY_MAX_LENGTH,
      `Company name should be ${COMPANY_MAX_LENGTH} characters or less`,
    ),
  jobTitle: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .max(
      JOB_TITLE_MAX_LENGTH,
      `Role or job title should be ${JOB_TITLE_MAX_LENGTH} characters or less`,
    ),
  country: validator.string().min(1, "Please select an option to proceed"),
  city: validator
    .string()
    .trim()
    .max(
      CITY_MAX_LENGTH,
      `City should be ${CITY_MAX_LENGTH} characters or less`,
    ),
  linkedIn: validator
    .string()
    .trim()
    .regex(
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/.+/i,
      "Please provide a valid LinkedIn profile URL",
    )
    .url("Please provide a LinkedIn URL.")
    .optional()
    .or(validator.literal("")),
});

type FormSchema = validator.infer<typeof schema>;

type Props = {
  onSubmit: () => void;
  actionButtonGroup: React.ReactNode;
};

export const CLIENT_PROFILE_FORM_ID = "clientProfile";

export function ClientProfileForm(props: Props) {
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();
  const { formState, getValues, register, handleSubmit } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    reValidateMode: "onBlur",
    defaultValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      company: user?.clientProfile?.company?.name || "",
      jobTitle: user?.clientProfile?.jobTitle || "",
      country: user?.countryCode || "",
      city: user?.city || "",
      linkedIn: user?.clientProfile?.linkedinUrl || "",
    },
  });

  const saveData = () => {
    updateUser({
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      countryCode: getValues("country"),
      city: getValues("city"),
      clientProfile: {
        ...user?.clientProfile,
        company: {
          ...user?.clientProfile?.company,
          name: getValues("company"),
        },
        jobTitle: getValues("jobTitle"),
        ...(getValues("linkedIn")
          ? { linkedinUrl: getValues("linkedIn") }
          : {}),
      },
    });
  };

  const onSubmit: SubmitHandler<FormSchema> = () => {
    saveData();
    props.onSubmit();

    Amplitude.track(
      ClientOnboardingEvent.name,
      ClientOnboardingEvent.properties.profileNext,
    );
  };

  // Auto-save data every 30 seconds or when the user is idle for 5 seconds
  useAutoTriggerAction(saveData);

  return (
    <Stack className="gap-4">
      <UserPhotoForm />
      <form
        name="clientProfile"
        id={CLIENT_PROFILE_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex className="flex-col">
          <SimpleGrid cols={{ lg: 2 }}>
            <TextInput
              label="First name"
              placeholder="First name"
              autoFocus
              error={formState.errors.firstName?.message}
              {...register("firstName")}
            />
            <TextInput
              label="Last name"
              placeholder="Last name"
              error={formState.errors.lastName?.message}
              {...register("lastName")}
            />
          </SimpleGrid>
          <SimpleGrid cols={{ lg: 2 }}>
            <TextInput
              label="Company"
              placeholder="Company"
              autoFocus
              error={formState.errors.company?.message}
              {...register("company")}
            />
            <TextInput
              label="Role or job title"
              placeholder="Role or job title"
              error={formState.errors.jobTitle?.message}
              {...register("jobTitle")}
            />
          </SimpleGrid>
          <SimpleGrid cols={{ lg: 2 }}>
            <NativeSelect
              label="Country"
              error={formState.errors.country?.message}
              {...register("country", {
                // Immediately save the data when the country is selected
                onChange: saveData,
              })}
            >
              <option value="" disabled>
                Please select
              </option>
              {countryList.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </NativeSelect>
            <TextInput
              label="City"
              placeholder="City"
              required={false}
              error={formState.errors.city?.message}
              {...register("city")}
            />
          </SimpleGrid>
          <TextInput
            label="LinkedIn"
            placeholder="https://www.linkedin.com/in/username/"
            required={false}
            error={formState.errors.linkedIn?.message}
            {...register("linkedIn")}
          />
        </Flex>
      </form>
      {props.actionButtonGroup}
    </Stack>
  );
}

export function isProfileCompleted(user: User) {
  const parsed = schema.safeParse({
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.clientProfile?.company?.name,
    jobTitle: user.clientProfile?.jobTitle,
    country: user.countryCode,
    city: user.city,
    linkedIn: user.clientProfile?.linkedinUrl,
  });

  return parsed.success;
}
