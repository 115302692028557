import { Title } from "@flpstudio/design-system";
import type { NotificationDto } from "types";
import { Comment } from "./Comment";
import { Like } from "./Like";

export function NotificationList({
  notifications,
  title,
}: {
  notifications: NotificationDto[];
  title: string;
}) {
  if (notifications.length === 0) return null;

  return (
    <>
      <Title
        className="font-normal text-[color:--mantine-color-gray-6] text-base"
        order={2}
      >
        {title}
      </Title>
      <div className="flex flex-col gap-5">
        {notifications.map((notification) => {
          switch (notification.notificationType) {
            case "COMMENT_MY_DOCUMENT":
            case "COMMENT_FOLLOWED_DOCUMENT":
              return (
                <Comment notification={notification} key={notification.id} />
              );
            case "LIKE_MY_DOCUMENT":
              return <Like notification={notification} key={notification.id} />;
          }
        })}
      </div>
    </>
  );
}
