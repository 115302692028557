import { ClientNextStepCallout } from "@/components/organisms/UserCallouts/ClientNextStepCallout";

export function ClientOnboardingCallout() {
  return (
    <div className="w-dvw lg:hidden">
      <div className="m-6 mt-0 rounded-lg border border-[#0065F2] border-solid empty:hidden">
        <ClientNextStepCallout />
      </div>
    </div>
  );
}
