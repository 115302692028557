import {
  Button,
  Flex,
  Group,
  Modal,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { ExpertPreferencesForm } from "@/components/organisms/ExpertPreferencesForm/ExpertPreferencesForm";
import { useAuth } from "@/hooks/use-auth";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";
import { CLIENT_MATURITY } from "@/utils/client-maturity";
import { CLIENT_SIZE } from "@/utils/client-size";
import * as styles from "./Profile.module.css";

const ExpertPreferences = () => {
  const { user } = useAuth();
  const [opened, handlers] = useDisclosure();

  // Checking mandatory fields to determine if the profile is complete.
  const isComplete = Boolean(
    user?.expertProfile?.preferClientSizes?.length &&
      user?.expertProfile?.preferClientMaturities?.length,
  );

  const cancelUpdate = () => {
    handlers.close();
    Amplitude.track(
      ExpertOwnProfileEvent.name,
      ExpertOwnProfileEvent.properties.preferenceModalClose,
    );
  };

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={2} className={styles.title}>
          IT client preferences
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ExpertOwnProfileEvent.name,
              ExpertOwnProfileEvent.properties.editClientPreferences,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {isComplete && (
        <>
          <Text>
            What size of client company are you most comfortable advising?
          </Text>
          <Text className={styles.secondaryText}>
            {CLIENT_SIZE.filter((size) =>
              user?.expertProfile?.preferClientSizes?.includes(size.value),
            )
              .map((size) =>
                size.value !== "CLIENT_SIZE_JUST_ME"
                  ? `${size.label} employees`
                  : "Single employee",
              )
              .join(", ")}
          </Text>
          <br />
          <Text>
            What stage of IT maturity are you able to support in client
            organizations?
          </Text>
          {CLIENT_MATURITY.filter((maturity) =>
            user?.expertProfile?.preferClientMaturities?.includes(
              maturity.value,
            ),
          ).map((maturity) => (
            <Text key={maturity.value} className={styles.secondaryText}>
              {maturity.label}
            </Text>
          ))}
        </>
      )}
      {!isComplete && (
        <Text className={styles.secondaryText}>
          Please add your IT client preferences. This information will help us
          match you with potential clients.
        </Text>
      )}
      <Modal
        opened={opened}
        onClose={cancelUpdate}
        title="IT client preferences"
      >
        <ExpertPreferencesForm
          actionButtonGroup={
            <Flex className="-mb-6 sticky bottom-0 z-10 flex-col bg-white pb-6 lg:flex-row-reverse">
              <Button type="submit">Submit</Button>
              <Button variant="outline" onClick={cancelUpdate}>
                Cancel
              </Button>
            </Flex>
          }
          onSubmit={handlers.close}
        />
      </Modal>
    </>
  );
};

export { ExpertPreferences };
