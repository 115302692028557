import type { ClientSize } from "types";

const CLIENT_SIZE: { label: string; expertLabel: string; value: ClientSize }[] =
  [
    {
      label: "Just me",
      expertLabel: "1",
      value: "CLIENT_SIZE_JUST_ME",
    },
    {
      label: "2-5",
      expertLabel: "2-5",
      value: "CLIENT_SIZE_2_TO_5",
    },
    {
      label: "6-50",
      expertLabel: "6-50",
      value: "CLIENT_SIZE_6_TO_50",
    },
    {
      label: "51-100",
      expertLabel: "51-100",
      value: "CLIENT_SIZE_51_TO_100",
    },
    {
      label: "100+",
      expertLabel: "100+",
      value: "CLIENT_SIZE_OVER_100",
    },
  ];

export { CLIENT_SIZE };
