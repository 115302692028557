import { Modal } from "@flpstudio/design-system";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { NavigationBar } from "@/components/organisms/NavigationBar/NavigationBar";
import { SignInOptions } from "@/components/organisms/SignIn/SignIn";
import { ViewExpertProfileDialog } from "@/components/organisms/ViewExpertProfileDialog/ViewExpertProfileDialog";
import { useAuth } from "@/hooks/use-auth";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "@/routes/paths";
import { useDisclosure } from "@mantine/hooks";

export function Unauthenticated() {
  const { user } = useAuth();
  const [redirectTo, setRedirectTo] = useState("");
  useEffect(() => {
    if (!user) {
      return;
    }

    const areAgreementsAccepted = !!user.termsAcceptedAt;
    const isUserTypeKnown = user.expertProfile || user.clientProfile;

    // bring user to the onboarding flow if they signup from public pages
    if (!areAgreementsAccepted) {
      setRedirectTo(paths.usageAgreement);
    } else if (!isUserTypeKnown) {
      setRedirectTo(paths.userTypeSelection);
    }
  }, [user]);

  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  return (
    <div>
      <NavigationBar />
      <Outlet />
      <ViewExpertProfileDialog />
      <SignInModal />
    </div>
  );
}

function SignInModal() {
  const { isSignUpModalOpen, clearSignUpSearchParams } =
    useSignUpSearchParams();
  const { user } = useAuth();

  useEffect(() => {
    if (isSignUpModalOpen) {
      handlers.open();
    } else {
      handlers.close();
    }
  }, [isSignUpModalOpen]);

  const [opened, handlers] = useDisclosure(isSignUpModalOpen);

  if (user) {
    return null;
  }

  return (
    <Modal
      zIndex={999}
      classNames={{
        body: "p-10 pt-0",
        content: "lg:max-w-[30rem]",
        header: "p-2 pb-1",
      }}
      opened={opened}
      withCloseButton
      onClose={() => {
        clearSignUpSearchParams();
      }}
    >
      <SignInOptions />
    </Modal>
  );
}
