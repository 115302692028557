type FormatMoneyConfig = {
  currency: string;
  amount: string;
  showDecimal?: boolean;
};

export function formatMoney({
  currency,
  amount,
  showDecimal = true,
}: FormatMoneyConfig) {
  if (!showDecimal) {
    const [wholeNumber, decimal] = amount.split(".");

    if (decimal === "00") {
      amount = wholeNumber || "";
    }
  }

  return `$${amount} ${currency}`;
}
