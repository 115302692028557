import { faChevronsRight } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Button, Group, Stack } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";

import { useAuth } from "@/hooks/use-auth";
import {
  useExpert,
  useExpertDocuments,
  useExpertSoftwareSelection,
} from "@/hooks/use-expert";
import {
  useExpertFullProfileSearchParams,
  useSignUpSearchParams,
} from "@/hooks/use-page-search-params";
import {
  Amplitude,
  ExpertProfileEvent,
  buildDocumentProperties,
  buildExpertProperties,
} from "@/third-party/amplitude";
import type { SDocument } from "types";
import { Drawer } from "../../molecules/Drawer/Drawer";
import { Booking } from "../../organisms/Booking/Booking";
import { ExpertProfile } from "../../organisms/ExpertProfile/ExpertProfile";

export function ViewExpertProfileDialog() {
  const [opened, handlers] = useDisclosure();
  const { openSignUpModal } = useSignUpSearchParams();

  const { user } = useAuth();

  const { getExpertIdentifier, clearExpertIdentifier, isExpertProfileToFront } =
    useExpertFullProfileSearchParams();

  const expertIdentifier = getExpertIdentifier();
  const { data: expert } = useExpert({ identifier: expertIdentifier });
  const { data: softwares } = useExpertSoftwareSelection({
    identifier: expert?.urlId || expert?.id,
  });
  const { data: documents } = useExpertDocuments({
    identifier: expert?.urlId || expert?.id,
  });

  useEffect(() => {
    if (expertIdentifier) {
      handlers.open();
    }

    return () => handlers.close();
  }, [expertIdentifier, handlers]);

  function handleSendEvent(action: string, document?: SDocument) {
    if (expert && documents && softwares) {
      Amplitude.track(ExpertProfileEvent.name, {
        action,
        user_status: user ? "Logged In" : "Logged Out",
        expert_published_document_count: documents.length,
        expert_software_expertise: softwares.map((software) => software.name),
        ...buildExpertProperties(expert),
        ...(document && buildDocumentProperties(document)),
      });
    }
  }

  return (
    <Drawer
      zIndex={isExpertProfileToFront ? 300 : ""}
      opened={opened}
      onClose={clearExpertIdentifier}
    >
      <Stack className="mt-6">
        <Group className="flex-nowrap items-stretch gap-2">
          <Button
            variant="outline"
            aria-label="Close preview"
            className="mr-auto h-auto border-none p-1 text-[--mantine-color-gray-7]"
            onClick={clearExpertIdentifier}
          >
            <FontAwesomeIcon icon={faChevronsRight} />
          </Button>
          {user ? (
            <Booking
              expertIdentifier={expertIdentifier}
              initiateOnSearchParams
              onBookSessionClick={(bookingType) => {
                handleSendEvent(
                  ExpertProfileEvent.properties[
                    bookingType === "INTRO_BOOKING"
                      ? "clickBookIntroSession"
                      : "clickBookAdvisorySession"
                  ].action,
                );
              }}
            />
          ) : (
            <Button onClick={openSignUpModal}>Sign up to book a session</Button>
          )}
        </Group>
        <ExpertProfile
          identifier={expertIdentifier}
          onDocumentClick={(document) => {
            handleSendEvent(
              ExpertProfileEvent.properties.clickDocument.action,
              document,
            );
          }}
        />
      </Stack>
    </Drawer>
  );
}
