import type { InitiateSSOProps } from "@/third-party/microsoft";
import { useCallback } from "react";

const useMicrosoftSSO = () => {
  const initiateSSO = useCallback(async (props: InitiateSSOProps) => {
    const { initiateSSO: initiateMicrosoftSSO } = await import(
      "@/third-party/microsoft"
    );

    await initiateMicrosoftSSO(props);
  }, []);

  return {
    initiateSSO,
  };
};

export { useMicrosoftSSO };
