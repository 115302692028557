import type { SDocumentContentPrompt } from "types";

const topicPrompts: SDocumentContentPrompt[] = [
  {
    id: "878cc0e7-31e8-41d2-8068-af9ea76ff72a",
    title: "%topic%",
    subPrompts: [
      {
        id: "919f73c2-49c9-46ee-9b87-ecb0dff79815",
        title: "%topic%",
        description:
          "Please write a short guide responding to the above prompt. Keep in mind that your audience is small & medium businesses and startups, with varying levels of IT knowledge.",
        value: "",
        required: true,
        metadata: {
          relevantArticles: {
            value: [],
            required: false,
          },
          recommendations: {
            value: [],
            required: false,
          },
        },
      },
    ],
  },
];

export { topicPrompts };
