import { Button, Modal, Stack, Text, Tooltip } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";
import { clsx } from "clsx/lite";
import { useState } from "react";
import { generatePath, useMatch, useNavigate } from "react-router-dom";

import { OptionButton } from "@/components/atoms/OptionButton/OptionButton";
import { CreateDocumentSelectSoftwareForm } from "@/components/organisms/CreateDocumentSelectSoftwareForm/CreateDocumentSelectSoftwareForm";
import { CreateDocumentSelectTopicForm } from "@/components/organisms/CreateDocumentSelectTopicForm/CreateDocumentSelectTopicForm";
import { ExpertIncompleteProfileSetup } from "@/components/organisms/ExpertIncompleteProfileSetup/ExpertIncompleteProfileSetup";
import { useAuth } from "@/hooks/use-auth";
import { useScreenSize } from "@/hooks/use-screen-size";
import { paths } from "@/routes/paths";
import { Amplitude, DocumentCalloutEvent } from "@/third-party/amplitude";
import { isExpertProfileCompleted } from "@/utils/user-profile";
import type { SDocumentType } from "types";

const options: { label: string; value: SDocumentType }[] = [
  {
    label: "Software guide",
    value: "SOFTWARE_CHECKLIST",
  },
  {
    label: "IT best practices",
    value: "IT_BEST_PRACTICE",
  },
];

function Steps() {
  return (
    <ol className="m-0 pl-7">
      <li>Write your guide, following our prompts.</li>
      <li>GuideStack team reviews and may request editing.</li>
      <li>
        If approved, your document will be available to all platform members.
      </li>
      <li>
        You will become available as an expert after your first approved
        submission, and can be booked by others for paid consulting sessions.
      </li>
    </ol>
  );
}

type CreateDocumentFormProps = {
  layout?: "vertical" | "horizontal";
  className?: string;
};

const CreateDocumentForm = ({
  layout = "vertical",
  className,
}: CreateDocumentFormProps) => {
  const [showError, setShowError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { isLg } = useScreenSize();
  const { user } = useAuth();
  const navigate = useNavigate();
  const matchOwnDocumentPath = useMatch(paths.profile.documents.root);
  const matchMainDashboardPath = useMatch(paths.documentation.root);

  const [createDocumentType, setCreateDocumentType] = useState<
    SDocumentType | string
  >("");
  const [opened, handlers] = useDisclosure();
  const isExpertProfileComplete = isExpertProfileCompleted(user);

  const CreateDocumentModalForm =
    createDocumentType === "SOFTWARE_CHECKLIST"
      ? CreateDocumentSelectSoftwareForm
      : CreateDocumentSelectTopicForm;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      documentType: { value: SDocumentType };
    };
    const documentType = target.documentType.value;

    setShowError(!documentType);

    if (documentType) {
      setCreateDocumentType(documentType);
      handlers.open();
    }
  };

  return (
    <>
      <form name="createDocument" onSubmit={handleSubmit} className={className}>
        <Stack>
          <Text className="font-semibold text-xl">
            Create a guide to share with the GuideStack community
          </Text>
          <Text>Select a category to get started</Text>
          <div
            className={clsx(
              "flex flex-col flex-wrap gap-4",
              layout === "horizontal" && "lg:flex-row",
            )}
          >
            {options.map((option) => (
              <OptionButton
                key={option.value}
                className="flex-grow"
                label={
                  <Text span className="block py-2">
                    {option.label}
                  </Text>
                }
                name="documentType"
                value={option.value}
              />
            ))}
          </div>
          {showError && (
            <Text className="text-[color:--mantine-color-error]">
              Please select a category
            </Text>
          )}
          <Button
            className={clsx(layout === "horizontal" && "lg:w-fit lg:self-end")}
            type="submit"
          >
            Create a guide
          </Button>
          {layout === "vertical" &&
            (!isLg ? (
              <Tooltip label={<Steps />} opened={showTooltip}>
                <Button
                  variant="transparent"
                  onClick={() => setShowTooltip((s) => !s)}
                  className="underline"
                >
                  How does it work?
                </Button>
              </Tooltip>
            ) : (
              <div>
                <Text span className="font-semibold">
                  How it works
                </Text>
                <Steps />
              </div>
            ))}
        </Stack>
      </form>
      {isExpertProfileComplete ? (
        // Show only when Expert profile is complete
        <Modal
          opened={opened}
          title={`Select a ${
            createDocumentType === "SOFTWARE_CHECKLIST" ? "software" : "topic"
          } to write about`}
          onClose={handlers.close}
        >
          <CreateDocumentModalForm
            onSubmit={(document) => {
              Amplitude.track(DocumentCalloutEvent.name, {
                ...DocumentCalloutEvent.properties.clickCreate,
                document_type: document.docType,
                location: matchMainDashboardPath
                  ? "Main Dashboard"
                  : matchOwnDocumentPath
                    ? "Own Documents"
                    : "",
              });
              navigate(
                generatePath(paths.profile.documents.edit, {
                  documentId: document.id,
                }),
              );
            }}
            onCancel={handlers.close}
            withStickyControls
          />
        </Modal>
      ) : (
        // Show when Expert profile is not complete or skipped the onboarding
        <Modal
          opened={opened}
          title="Please complete profile setup first"
          onClose={handlers.close}
        >
          <ExpertIncompleteProfileSetup onCancel={handlers.close} />
        </Modal>
      )}
    </>
  );
};

export { CreateDocumentForm };
