import { faClose } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Combobox,
  Group,
  Text,
  TextInput,
  UnstyledButton,
  useCombobox,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx/lite";
import { forwardRef, useState } from "react";

import type { SoftwareWithPublishedDocumentsDto } from "@/api/openapi";
import { useSoftware } from "@/hooks/use-software";

type SelectedSoftware = Omit<
  SoftwareWithPublishedDocumentsDto,
  "maxDocumentCount" | "currentDocumentCount"
>;
type Props = {
  label?: string;
  placeholder?: string;
  value?: SelectedSoftware[];
  name?: string;
  onChange?: (softwareArray: SelectedSoftware[]) => void;
  onBlur?: () => void;
  error?: string;
  required?: boolean;
  disabled?: boolean;
};

const SoftwareList = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [search, setSearch] = useState("");
  const { data: fullList } = useSoftware();
  const comboBox = useCombobox({});

  const addToList = (software: SelectedSoftware) => {
    const newList = [...(props.value || []), software].sort(
      (softwareA, softwareB) => softwareA.name.localeCompare(softwareB.name),
    );
    props.onChange?.(newList);
  };

  const filteredList =
    fullList?.filter(
      (software) =>
        !props.value?.map((software) => software.id).includes(software.id) &&
        software.name.toLowerCase().includes(search.toLowerCase()),
    ) || [];

  return (
    <div>
      <Combobox
        store={comboBox}
        onOptionSubmit={(softwareId) => {
          const software = fullList?.find(
            (software) => software.id === softwareId,
          );
          if (software) {
            addToList(software);
            setSearch("");
          }
        }}
        disabled={props.disabled}
      >
        <Combobox.Target>
          <TextInput
            ref={ref}
            label={props.label}
            required={props.required}
            placeholder={props.placeholder}
            name={props.name}
            error={props.error}
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents="none"
            onClick={() => comboBox.openDropdown()}
            onFocus={() => comboBox.openDropdown()}
            onBlur={() => {
              comboBox.closeDropdown();
              props.onBlur?.();
            }}
            onKeyDown={(event) => {
              const searchLowerCase = search.toLowerCase();

              const searchIndexFromValueProps = (props.value || []).findIndex(
                (software) => software.name.toLowerCase() === searchLowerCase,
              );
              const searchIndexFromFullList = (fullList || []).findIndex(
                (software) => software.name.toLowerCase() === searchLowerCase,
              );

              // Prevent duplicates by checking if the search value is already in the list
              if (
                event.key === "Enter" &&
                search.trim().length > 0 &&
                searchIndexFromValueProps === -1 &&
                searchIndexFromFullList === -1
              ) {
                event.preventDefault();
                addToList({
                  id: crypto.randomUUID(),
                  name: search,
                  icon: "",
                  categoryId: "",
                  popular: false,
                  status: "",
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  website: "",
                });
                setSearch("");
              }
            }}
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            disabled={props.disabled}
          />
        </Combobox.Target>
        <Combobox.Dropdown className="max-h-60 overflow-y-auto">
          <Combobox.Options>
            {filteredList.length === 0 ? (
              <Combobox.Empty className="text-left">
                <Text span className="text-sm/normal">
                  No software found, press Enter to add as a custom tool
                </Text>
              </Combobox.Empty>
            ) : (
              filteredList.map((software) => (
                <Combobox.Option key={software.id} value={software.id}>
                  <Group gap={8} className="flex-nowrap">
                    <img
                      src={software.icon}
                      alt={software.name}
                      className="size-5"
                    />
                    <Text span className="line-clamp-1 text-sm/normal">
                      {software.name}
                    </Text>
                  </Group>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {props.value && props.value.length > 0 && (
        <Group className="mt-2 gap-2">
          {props.value.map((software) => (
            <Group
              key={software.id}
              className={clsx(
                "max-w-full flex-nowrap gap-2 rounded p-2",
                props.disabled
                  ? "bg-[--mantine-color-gray-1] text-[--mantine-color-gray-6]"
                  : "bg-[--mantine-primary-color-0] text-[--mantine-primary-color-5]",
              )}
            >
              {software.icon && (
                <img
                  src={software.icon}
                  alt={software.name}
                  className="size-5"
                />
              )}
              <span key={software.id} className="line-clamp-1">
                {software.name}
              </span>
              <UnstyledButton
                onClick={() => {
                  if (props.value) {
                    props.onChange?.(
                      props.value.filter((item) => item.id !== software.id),
                    );
                  }
                }}
                className="flex size-4 rounded"
                aria-label={`Remove ${software.name}`}
                disabled={props.disabled}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  className={clsx(
                    "size-4",
                    props.disabled
                      ? "cursor-not-allowed text-[--mantine-color-gray-5]"
                      : "text-[--mantine-primary-color-6]",
                  )}
                />
              </UnstyledButton>
            </Group>
          ))}
        </Group>
      )}
    </div>
  );
});

export { SoftwareList };
