import { Text } from "@flpstudio/design-system";

export function TextOverflowWrapper({
  children,
  className,
}: { children: React.ReactNode; className?: string }) {
  // TODO: use tailwind when `overflow-wrap: anywhere` is supported: https://github.com/tailwindlabs/tailwindcss/discussions/2213#discussioncomment-9772780
  return (
    <Text className={className} style={{ overflowWrap: "anywhere" }}>
      {children}
    </Text>
  );
}
