import { faPencil } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Stack,
  TextArea,
  type TextAreaProps,
  UnstyledButton,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedCallback } from "@mantine/hooks";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { LinkPreview } from "@/components/atoms/LinkPreview/LinkPreview";
import { useDocumentLinkMetadata } from "@/hooks/use-documents";
import { appendProtocol, isValidUrl } from "@/utils/url";
import type { SDocumentPromptMetadata } from "types";

type Props = Omit<TextAreaProps, "value" | "onChange"> & {
  value?: SDocumentPromptMetadata[];
  onChange?: (metadataArray: SDocumentPromptMetadata[]) => void;
  onLinkPreviewClick?: (metadataIndex: number) => void;
};

export const EditLinkPreviewList = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, onLinkPreviewClick, ...props }, ref) => {
    // We need to cache the original value to keep the user's modifications to existing links when they add new ones
    const cachedValue = useRef<SDocumentPromptMetadata[]>([]);

    // The links that are entered by the user in the text area
    const [textAreaValue, setTextAreaValue] = useState("");

    // The links that are entered by the user in the text area, converted to an array
    // This array is used to fetch metadata for the links
    const [urlArray, setUrlArray] = useState<string[]>([]);

    useEffect(() => {
      if (props.value) {
        cachedValue.current = [...props.value];

        if (!urlArray.length) {
          setTextAreaValue(
            props.value.map((metadata) => metadata.url).join("\n"),
          );

          return;
        }

        setTextAreaValue((prev) => {
          // Do the update only once the props.value and the textbox value have the same number of links
          if (props.value?.length === prev.match(/\S+/g)?.length) {
            // Split the textbox value into an array of strings and whitespaces
            // "abc def    ghi" => ["abc", " ", "def", "    ", "ghi"]
            const valueArr = prev.match(/\S+|\s+/g) || [];

            let nonWhiteSpaceCounter = 0;

            const updateValueArr = valueArr.map((str) => {
              // Non-whitespace strings are urls
              if (str.trim()) {
                const url = props.value?.[nonWhiteSpaceCounter]?.url || "";
                nonWhiteSpaceCounter++;
                return url;
              }

              // Whitespaces are kept as is
              return str;
            });

            return updateValueArr.join("");
          }

          return prev;
        });
      }
    }, [props.value, urlArray]);

    const convertTextAreaValueToUrlArray = useDebouncedCallback(
      (textAreaValue: string) => {
        const trimmedValue = textAreaValue.trim().toLowerCase();

        if (!trimmedValue) {
          setUrlArray([]);
          props.onChange?.([]);
          return;
        }

        const urlArray = trimmedValue.match(/\S+/g) || [];

        // Remove duplicates and attempt to append protocol to urls
        const newUrlArray = Array.from(
          new Set(
            urlArray.map((url: string) =>
              isValidUrl(url) ? appendProtocol(url) : url,
            ),
          ),
        );

        if (!urlArray.length) {
          setUrlArray(newUrlArray);
          return; // No need to check for changes if the array is empty
        }

        setUrlArray(newUrlArray);
      },
      1000,
    );

    const { data: metadataArray, isFetched } = useDocumentLinkMetadata({
      urlArray,
    });

    const updatedValue = useMemo(
      () =>
        metadataArray.map((metadata, index) => {
          // Check if the url is already in the cachedValue
          const originalMetadata = cachedValue.current.find(
            (origMeta) => origMeta.url === urlArray[index],
          );

          // If the user modified the metadata on those urls, keep the modified metadata
          // Otherwise, use the fetched metadata
          if (originalMetadata?.title) {
            return originalMetadata;
          }

          const url = urlArray[index] || "";

          if (!isFetched) {
            return {
              url,
              title: "",
              description: "",
              favicon: "",
            };
          }

          return {
            url,
            title: metadata?.title || "No title found",
            description: metadata?.description || "No description found",
            favicon: metadata?.favicon || "",
          };
        }),
      [isFetched, metadataArray, urlArray],
    );

    useEffect(() => {
      if (updatedValue.length) {
        props.onChange?.(updatedValue);
      }
    }, [updatedValue, props.onChange]);

    return (
      <Stack className={className}>
        <TextArea
          {...props}
          ref={ref}
          autosize
          minRows={1}
          value={textAreaValue}
          onChange={(event) => {
            setTextAreaValue(event.target.value);
            convertTextAreaValueToUrlArray(event.target.value);
          }}
        />
        {props.value && props.value.length > 0 && (
          <ul className="m-0 flex list-none flex-col gap-4 p-0">
            {props.value.map((metadata, index) => (
              <li key={metadata.url} className="flex gap-2">
                <LinkPreview
                  favicon={metadata.favicon}
                  href={metadata.url}
                  title={metadata.title || "Fetching title..."}
                  description={
                    metadata.description || "Fetching description..."
                  }
                />
                <UnstyledButton
                  onClick={() => onLinkPreviewClick?.(index)}
                  aria-label="Edit link"
                  className="shrink-0"
                >
                  <FontAwesomeIcon
                    icon={faPencil}
                    className="size-4 text-[--mantine-color-gray-6]"
                  />
                </UnstyledButton>
              </li>
            ))}
          </ul>
        )}
      </Stack>
    );
  },
);

EditLinkPreviewList.displayName = "EditLinkPreviewList";
