import {
  Button,
  Flex,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useVoucherMutation } from "@/hooks/use-voucher-mutation";
import { Amplitude, RedeemVoucherEvent } from "@/third-party/amplitude";

const STRING_MIN_LENGTH = 1;
const STRING_MAX_LENGTH = 256;

const MIN_LENGTH_MESSAGE = "Please enter a voucher code";
const MAX_LENGTH_MESSAGE = "Voucher code is too long";
const INVALID_MESSAGE =
  "This code is not valid. Please double check and try again.";

const schema = validator.object({
  voucherCode: validator
    .string()
    .min(STRING_MIN_LENGTH, MIN_LENGTH_MESSAGE)
    .max(STRING_MAX_LENGTH, MAX_LENGTH_MESSAGE),
});

type FormSchema = validator.infer<typeof schema>;

type EnterVoucherFormProps = {
  onBack: () => void;
  onClose: () => void;
  onContinue: () => void;
  onSuccess: () => void;
  expertIdentifier: string;
};

function EnterVoucherForm(props: EnterVoucherFormProps) {
  const { formState, register, handleSubmit, setError } = useForm<FormSchema>({
    defaultValues: {
      voucherCode: "",
    },
    reValidateMode: "onBlur",
    resolver: zodResolver(schema),
  });

  const { mutate, isPending, isSuccess } = useVoucherMutation({
    expertIdentifier: props.expertIdentifier,
  });

  const onSubmit: SubmitHandler<FormSchema> = (data) => {
    Amplitude.track(RedeemVoucherEvent.name, {
      ...RedeemVoucherEvent.properties.clickRedeem,
    });

    mutate(
      { code: data.voucherCode },
      {
        onSuccess: props.onSuccess,
        onError: () => setError("voucherCode", { message: INVALID_MESSAGE }),
      },
    );
  };

  if (isSuccess) {
    return (
      <Stack>
        <Text>
          You have one free 60-min session available! You may book this session
          with any expert on the platform.
        </Text>
        <Flex className="flex-col lg:flex-row-reverse">
          <Button
            onClick={() => {
              Amplitude.track(RedeemVoucherEvent.name, {
                ...RedeemVoucherEvent.properties.clickContinueBooking,
              });
              props.onContinue();
            }}
          >
            Continue booking
          </Button>
          <Button variant="outline" onClick={props.onClose}>
            Close
          </Button>
        </Flex>
      </Stack>
    );
  }

  return (
    <form
      name="enterVoucher"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      <TextInput
        aria-label="Voucher code"
        placeholder="Paste your voucher code here"
        {...register("voucherCode")}
        error={formState.errors.voucherCode?.message}
        disabled={isPending}
      />
      <Flex className="flex-col lg:flex-row-reverse">
        <Button type="submit" loading={isPending}>
          Redeem
        </Button>
        <Button variant="outline" onClick={props.onBack}>
          Back
        </Button>
      </Flex>
    </form>
  );
}

type EnterVoucherDialogProps = {
  opened: boolean;
  onBack: () => void;
  onClose: () => void;
  onContinue: () => void;
  expertIdentifier: string;
};

export function EnterVoucherDialog(props: EnterVoucherDialogProps) {
  const [isSuccess, setIsSuccess] = useState(false);

  function handleClose() {
    setIsSuccess(false);
    props.onClose();
  }

  function handleContinue() {
    setIsSuccess(false);
    props.onContinue();
  }

  return (
    <Modal
      opened={props.opened}
      onClose={handleClose}
      title={
        isSuccess
          ? "1 free session added to your account"
          : "Enter voucher code"
      }
    >
      <EnterVoucherForm
        onBack={props.onBack}
        onClose={handleClose}
        onContinue={handleContinue}
        expertIdentifier={props.expertIdentifier}
        onSuccess={() => setIsSuccess(true)}
      />
    </Modal>
  );
}
