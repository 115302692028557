import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { CURRENT_USER_QUERYKEY } from "@/providers/AuthProvider";
import type { User } from "types";

export function useUpdateUserMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: Partial<User>) => {
      return axios.patch<User>("/users/me", user);
    },
    onMutate: async (updatedUser) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: CURRENT_USER_QUERYKEY });

      // Optimistically update to the new value
      queryClient.setQueryData(
        CURRENT_USER_QUERYKEY,
        (previousUser: Partial<User>) => {
          return {
            ...previousUser,
            ...updatedUser,
          };
        },
      );
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentUser"],
      });
    },
  });
}

export function useDeleteUserMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return axios.delete("/users/me");
    },
    onSuccess: () => {
      queryClient.clear();
    },
  });
}
