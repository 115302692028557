import {
  Anchor,
  Button,
  Container,
  Flex,
  Grid,
  Modal,
  Paper,
  Stack,
  Stepper,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import { Logo } from "@/components/atoms/Logo/Logo";
import { ExpertPreferencesForm } from "@/components/organisms/ExpertPreferencesForm/ExpertPreferencesForm";
import {
  EXPERT_PROFILE_FORM_ID,
  ExpertProfileForm,
} from "@/components/organisms/ExpertProfileForm/ExpertProfileForm";
import { useAuth } from "@/hooks/use-auth";
import { useScreenSize } from "@/hooks/use-screen-size";
import { paths } from "@/routes/paths";
import { Amplitude, ExpertOnboardingEvent } from "@/third-party/amplitude";

export function ExpertOnboarding() {
  const { user } = useAuth();
  const [step, setStep] = useState(1);
  const { isLg } = useScreenSize();

  if (user?.clientProfile) {
    return <Navigate to={paths.root} />;
  }

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    setStep(step - 1);
  };

  return (
    <>
      <Container>
        <header className="flex items-center justify-between">
          <Logo />
          <Anchor
            component={Link}
            onClick={() => {
              Amplitude.track(
                ExpertOnboardingEvent.name,
                ExpertOnboardingEvent.properties.skipOnboarding,
              );
            }}
            to={paths.root}
            className="text-[color:--mantine-color-gray-5]"
          >
            Skip onboarding
          </Anchor>
        </header>
        <Grid component="main" className="mt-10">
          <Grid.Col span={{ lg: 2 }} className="hidden lg:block">
            <Stepper
              active={step}
              orientation={isLg ? "vertical" : "horizontal"}
              className="mt-6"
            >
              <Stepper.Step label="Step 1" description="Your Profile" />
              <Stepper.Step label="Step 2" description="Preferences" />
            </Stepper>
          </Grid.Col>
          <Grid.Col span={{ lg: 7 }}>
            <Paper className="-mx-6 my-0 lg:m-0">
              {step === 1 && (
                <>
                  <Title className="mb-4 text-xl">Your profile</Title>
                  <ExpertProfileForm
                    actionButtonGroup={
                      <Flex className="flex-col lg:flex-row-reverse">
                        <Button type="submit" form={EXPERT_PROFILE_FORM_ID}>
                          Next
                        </Button>
                      </Flex>
                    }
                    onSubmit={onNext}
                  />
                </>
              )}
              {step > 1 && (
                <>
                  <Title className="mb-4 text-xl">Preferences</Title>
                  <ExpertPreferencesForm
                    onSubmit={onNext}
                    actionButtonGroup={
                      <Flex className="flex-col lg:flex-row-reverse">
                        <Button type="submit">Submit</Button>
                        <Button
                          variant="outline"
                          onClick={onBack}
                          className="mt-4 lg:mt-0"
                        >
                          Back
                        </Button>
                      </Flex>
                    }
                  />
                </>
              )}
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ lg: 3 }}>
            <Paper component="aside" className="-m-6 p-6 lg:m-0">
              <Text className="font-semibold">
                Why does GuideStack collect this information?
              </Text>
              <br />
              <Text className="text-[color:--mantine-color-gray-7]">
                This information will help us recommend you to relevant clients.
                <br />
                <br />
                It will be visible to everyone on the platform.
              </Text>
            </Paper>
          </Grid.Col>
        </Grid>
      </Container>
      <Modal
        opened={step > 2}
        onClose={() => {
          /* noop */
        }}
        title="Great! You’ve set up your profile"
        size="md"
      >
        <Stack>
          <Text className="text-[color:--mantine-color-gray-7]">
            To become a GuideStack expert, write at least one guide about a
            software or IT strategy topic. Once approved, clients can book paid
            consultations with you.
          </Text>
          <Flex className="flex flex-col lg:flex-row-reverse lg:justify-between">
            <Button
              onClick={() => {
                Amplitude.track(
                  ExpertOnboardingEvent.name,
                  ExpertOnboardingEvent.properties.completeGoSubmit,
                );
              }}
              component={Link}
              to={paths.profile.documents.root}
            >
              Write a guide
            </Button>
            <Button
              component={Link}
              onClick={() => {
                Amplitude.track(
                  ExpertOnboardingEvent.name,
                  ExpertOnboardingEvent.properties.completeGoDashboard,
                );
              }}
              to={paths.root}
              variant="transparent"
              className="ps-0 pe-0 text-center"
            >
              Go to dashboard
            </Button>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
}
