const paths = {
  // Public paths
  root: "/",
  login: "/login",
  authCallback: "/auth-callback",
  notFound: "/404",

  external: {
    help: "https://guidestack.com/contact-us",
    tos: "https://guidestack.com/terms-of-service-guidestack",
    expertTos: "https://guidestack.com/terms-of-service-expert",
    fujitsuTos: "https://guidestack.com/terms-of-service-fujitsu-launchpad",
    privacy: "https://guidestack.com/privacy-policy",
    landing: "https://guidestack.com/",
    commercialTxnAct: "https://guidestack.com/tokusyohou",
    admin: `${import.meta.env.VITE_API_BASE_URL}/admin?ts=${Date.now()}`,
  },

  // Private paths
  usageAgreement: "/usage-agreement",
  userTypeSelection: "/user-type-selection",
  home: "/home",
  notifications: "/notifications",
  onboarding: {
    expert: "/onboarding/expert",
    client: "/onboarding/client",
  },
  profile: {
    root: "/me",
    documents: {
      root: "/me/documents",
      review: "/me/documents/review",
      published: "/me/documents/published",
      declined: "/me/documents/declined",
      edit: "/me/documents/edit/:documentId",
    },
  },
  documentation: {
    root: "/documents",
    viewer: "/documents/:urlSlug",
  },
  experts: {
    root: "/experts",
    profile: "/experts/:identifier",
  },
  sessions: {
    root: "/sessions",
    preview: "/sessions-preview",
    completed: "/sessions/completed",
  },
  account: {
    root: "/account",
    payouts: "/account/payouts",
    adminPayout: "/account/admin-payout",
  },
};

export { paths };
