import {
  Amplitude,
  DocumentInteractionsEvent,
  createDocumentInteractionsProperties,
} from "@/third-party/amplitude";
import type { SDocument, SoftwareDocument, User } from "types";

export function isSoftwareDocument(
  document: SDocument,
): document is SoftwareDocument {
  return document.docType === "SOFTWARE_CHECKLIST";
}

type BaseTrackingParams = {
  document: SDocument;
  user?: User | null;
};

export function sendExpertViewEvent({ document, user }: BaseTrackingParams) {
  Amplitude.track(DocumentInteractionsEvent.name, {
    action: DocumentInteractionsEvent.properties.openExpertProfile.action,
    ...createDocumentInteractionsProperties(document, Boolean(user?.id)),
  });
}

export function sendLinkPreviewClickEvent({
  document,
  user,
  url,
}: BaseTrackingParams & {
  url: string;
}) {
  Amplitude.track(DocumentInteractionsEvent.name, {
    action: DocumentInteractionsEvent.properties.clickLinkPreview.action,
    url,
    ...createDocumentInteractionsProperties(document, Boolean(user?.id)),
  });
}

export function sendSoftwareRecommendationClickEvent({
  document,
  user,
  software,
}: BaseTrackingParams & {
  software: string;
}) {
  Amplitude.track(DocumentInteractionsEvent.name, {
    action: DocumentInteractionsEvent.properties.clickSoftwareTool.action,
    software,
    ...createDocumentInteractionsProperties(document, Boolean(user?.id)),
  });
}

export function sendDocumentLikeEvent({ document, user }: BaseTrackingParams) {
  Amplitude.track(DocumentInteractionsEvent.name, {
    action:
      DocumentInteractionsEvent.properties[
        document.likedByMe ? "unlikeDocument" : "likeDocument"
      ].action,
    ...createDocumentInteractionsProperties(document, Boolean(user?.id)),
  });
}

export function sendDocumentViewEvent({ document, user }: BaseTrackingParams) {
  Amplitude.track(DocumentInteractionsEvent.name, {
    action: DocumentInteractionsEvent.properties.viewDocument.action,
    ...createDocumentInteractionsProperties(document, Boolean(user?.id)),
  });
}
