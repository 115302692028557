import {
  faMessageDots,
  faThumbsUp,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { faCircle } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { Anchor, Avatar, Grid, Group, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ReactNode } from "react";
import { Link, generatePath } from "react-router-dom";

import { UserBadge } from "@/components/organisms/Comment/UserBadge";
import { paths } from "@/routes/paths";
import { Amplitude, NotificationClickEvent } from "@/third-party/amplitude";
import { formatDate } from "@/utils/format-date";
import type {
  CommentNotification,
  LikeNotification,
  NotificationDto,
} from "types";

type NotificationType = "like" | "comment";
type NotificationProps = {
  notification: CommentNotification | LikeNotification;
  type: NotificationType;
  actionText: string;
  children?: ReactNode;
};

export function NotificationItem({
  notification,
  type,
  actionText,
  children,
}: NotificationProps) {
  return (
    <Grid>
      <Grid.Col span="content">
        <div className="-ml-4 flex items-center gap-2">
          <div className="size-2 leading-none">
            {!notification.hasRead && <UnreadSpot />}
          </div>
          <div className="relative">
            <Avatar src={notification.actionUser?.profileImage} alt="Profile" />
            <NotificationIcon type={type} />
          </div>
        </div>
      </Grid.Col>
      <Grid.Col span="auto">
        <div className="flex-col gap-1">
          <NameWithBadge
            name={notification.actionUser?.name || "User deactivated"}
            isExpert={notification.actionUser?.isExpert ?? false}
          />
          <Text className="text-[color:--mantine-color-gray-6] text-sm">
            {formatDate(new Date(notification.createdAt))}
          </Text>
          <Text className="text-sm">
            {actionText}{" "}
            <Anchor
              component={Link}
              to={generatePath(paths.documentation.viewer, {
                urlSlug: notification.document.urlTitle,
              })}
              className="text-sm underline"
              onClick={() => {
                handleNotificationClick(notification);
              }}
            >
              {notification.document.topic ||
                notification.document.softwareName}
            </Anchor>
          </Text>
          {children}
        </div>
      </Grid.Col>
    </Grid>
  );
}

function NotificationIcon({ type }: { type: "comment" | "like" }) {
  return (
    <div className="absolute top-1/2 left-1/2">
      <FontAwesomeIcon icon={faCircle} className="text-white" size="lg" />
      <FontAwesomeIcon
        icon={type === "comment" ? faMessageDots : faThumbsUp}
        className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform"
        size="xs"
      />
    </div>
  );
}

export function UnreadSpot() {
  return (
    <span className="relative inline-block h-full w-full rounded-full bg-[--mantine-color-orange-4]">
      <span className="sr-only">unread</span>
    </span>
  );
}

export function NameWithBadge({
  name,
  isExpert,
}: {
  name: string;
  isExpert: boolean;
}) {
  return (
    <Group className="gap-2 font-medium">
      {name}
      {isExpert && <UserBadge userType="expert" />}
    </Group>
  );
}

function handleNotificationClick(notification: NotificationDto) {
  let eventProperties = {};

  switch (notification.notificationType) {
    case "LIKE_MY_DOCUMENT":
      eventProperties = NotificationClickEvent.properties.userLikedDocument;
      break;
    case "COMMENT_MY_DOCUMENT":
      eventProperties = NotificationClickEvent.properties.userCommentedDocument;
      break;
    case "COMMENT_FOLLOWED_DOCUMENT":
      eventProperties =
        NotificationClickEvent.properties.userCommentedSameDocument;
      break;
    default:
      break;
  }

  Amplitude.track(NotificationClickEvent.name, {
    ...eventProperties,
    user_type: notification.actionUser?.isExpert ? "Expert" : "Client",
  });
}
