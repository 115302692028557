import parseHtml from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";

import { TextOverflowWrapper } from "@/components/atoms/TextOverflowWrapper";
import type { CommentNotification } from "types";
import { NotificationItem } from "./NotificationItem";

export function Comment({
  notification,
}: {
  notification: CommentNotification;
}) {
  const isTopic = Boolean(notification.document.topic);
  const isOwner = notification.notificationType === "COMMENT_MY_DOCUMENT";
  const documentText = isOwner ? "your document" : "the same document";

  const actionText = isTopic
    ? `Commented on ${documentText} ·`
    : `Commented on ${documentText} about`;

  return (
    <NotificationItem
      notification={notification}
      type="comment"
      actionText={actionText}
    >
      <TextOverflowWrapper className="text-[--mantine-color-gray-5] text-sm">
        {parseHtml(
          DOMPurify.sanitize(notification.comment.content || "", {
            ALLOWED_TAGS: ["br"],
          }),
        )}
      </TextOverflowWrapper>
    </NotificationItem>
  );
}
