import { useCallback } from "react";

import { paths } from "@/routes/paths";
import { useSearchParams } from "react-router-dom";

type ProfilePageModalName =
  | "myProfile"
  | "software"
  | "industry"
  | "itSetup"
  | "companyInfo";

export function useProfilePageSearchParams() {
  const [searchParams] = useSearchParams();

  const isModalOpen = (modalName: ProfilePageModalName) => {
    const ret = searchParams.get("profileModal") === modalName;

    return ret;
  };

  const generateModalOpenLink = (modalName: ProfilePageModalName) => {
    const res = `${paths.profile.root}?${new URLSearchParams({
      profileModal: modalName,
    }).toString()}`;

    return res;
  };

  return { isModalOpen, generateModalOpenLink };
}

export function useExpertFullProfileSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const expertProfileParam = "expertProfile";
  const bringExpertProfileToFront = "toFront";

  const getExpertIdentifier = () => {
    return searchParams.get(expertProfileParam) || "";
  };

  const isExpertProfileToFront = searchParams.get(bringExpertProfileToFront);

  const setExpertIdentifier = (identifier: string, toFront?: boolean) => {
    searchParams.set(expertProfileParam, identifier);
    if (toFront) {
      searchParams.set(bringExpertProfileToFront, "true");
    }
    setSearchParams(searchParams);
  };

  const clearExpertIdentifier = () => {
    searchParams.delete(expertProfileParam);
    searchParams.delete(bringExpertProfileToFront);
    setSearchParams(searchParams);
  };

  return {
    getExpertIdentifier,
    setExpertIdentifier,
    isExpertProfileToFront,
    clearExpertIdentifier,
  };
}

export function useBookingSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const bookSessionParam = "bookSession";
  const bookSessionValue = "start";

  const generateStartBookingLink = (url: string) => {
    const newUrl = new URL(url);
    newUrl.searchParams.set(bookSessionParam, bookSessionValue);
    return newUrl.toString();
  };

  const setStartBooking = () => {
    searchParams.set(bookSessionParam, bookSessionValue);
    setSearchParams(searchParams);
  };

  const clearStartBooking = () => {
    searchParams.delete(bookSessionParam);
    setSearchParams(searchParams);
  };

  const isStartBooking =
    searchParams.get(bookSessionParam) === bookSessionValue;

  return {
    generateStartBookingLink,
    setStartBooking,
    clearStartBooking,
    isStartBooking,
  };
}

export function useSessionSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sessionIdParam = "sessionId";

  const sessionId = searchParams.get(sessionIdParam) || "";

  const setSessionId = (sessionId: string) => {
    searchParams.set(sessionIdParam, sessionId);
    setSearchParams(searchParams);
  };

  const clearSession = () => {
    searchParams.delete(sessionIdParam);
    setSearchParams(searchParams);
  };

  return { sessionId, setSessionId, clearSession };
}

export function useOAuthSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const oAuthCodeParam = "code";

  const oAuthCode = searchParams.get(oAuthCodeParam) || "";

  const clearOAuthCode = useCallback(() => {
    searchParams.delete(oAuthCodeParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return { oAuthCode, clearOAuthCode };
}

export function useSignUpSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const name = "signUp";
  const value = "true";

  const isSignUpModalOpen = searchParams.get(name) === value;

  const openSignUpModal = () => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };

  const clearSignUpSearchParams = () => {
    searchParams.delete(name);
    setSearchParams(searchParams);
  };

  return { isSignUpModalOpen, clearSignUpSearchParams, openSignUpModal };
}
