import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";
import { useEffect } from "react";

import { useStripePaymentCreate } from "@/hooks/use-payment-mutation";
import { Amplitude, PaymentModalsEvent } from "@/third-party/amplitude";

type AddPaymentMethodProps = {
  onUseVoucherCode: () => void;
  onCancel: () => void;
  cancelCallbackUrl: string;
  successCallbackUrl: string;
};

function AddPaymentMethod(props: AddPaymentMethodProps) {
  const { data, mutate, isPending } = useStripePaymentCreate();

  useEffect(() => {
    mutate({
      cancelReturnUrl: props.cancelCallbackUrl,
      successReturnUrl: props.successCallbackUrl,
    });
  }, [mutate, props.cancelCallbackUrl, props.successCallbackUrl]);

  function handleSendEvent(action: string) {
    Amplitude.track(PaymentModalsEvent.name, {
      action,
    });
  }

  return (
    <form
      name="addPayment"
      aria-label="addPayment"
      method="GET"
      action={data?.paymentFormUrl}
    >
      <Stack>
        <Text>
          We require users to add payment information before booking a session.
          You will be charged only for completed sessions.
        </Text>
        <Text>
          GuideStack uses Stripe to manage payments. You can update your payment
          method anytime in Stripe.
        </Text>
        <Flex className="mt-4 flex-col lg:flex-row-reverse">
          <Button
            type="submit"
            loading={isPending}
            disabled={!data?.paymentFormUrl}
            onClick={() =>
              handleSendEvent(
                PaymentModalsEvent.properties.paymentModalAddPayment.action,
              )
            }
            className="lg:order-1"
          >
            Add payment
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              handleSendEvent(
                PaymentModalsEvent.properties.paymentModalUseVoucher.action,
              );
              props.onUseVoucherCode();
            }}
            className="lg:order-3 lg:mr-auto"
          >
            Use a voucher code
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              handleSendEvent(
                PaymentModalsEvent.properties.paymentModalCancel.action,
              );
              props.onCancel();
            }}
            className="lg:order-2"
          >
            Cancel
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

type DialogProps = AddPaymentMethodProps & {
  opened: boolean;
};

export const AddPaymentMethodDialog = (props: DialogProps) => {
  return (
    <Modal
      title="Please add a payment method"
      opened={props.opened}
      onClose={props.onCancel}
    >
      <AddPaymentMethod {...props} />
    </Modal>
  );
};
