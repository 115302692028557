import { Button, Flex, Text } from "@flpstudio/design-system";

type Props = {
  onDone?: () => void;
};

const SubmitSuccessDialog = (props: Props) => {
  return (
    <>
      <Text>
        You can track your document status on your "Guides written" page. Once
        approved, we will send you an email.
      </Text>
      <Flex className="mt-4 flex-col lg:flex-row-reverse">
        <Button onClick={props.onDone}>Done</Button>
      </Flex>
    </>
  );
};

export { SubmitSuccessDialog };
