import { useMutation, useQueryClient } from "@tanstack/react-query";

import { httpClient } from "@/api/client";
import { bookingQueryKey } from "@/hooks/use-booking";

export function useVoucherMutation({
  expertIdentifier,
}: { expertIdentifier: string }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ code }: { code: string }) => {
      return httpClient.paths["/v1/voucher/{code}/redeem"].post({ code });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [bookingQueryKey.advisoryBooking, expertIdentifier],
      });
    },
    onError: async (error) => {
      // Invalid voucher codes return a 400 status code
      // So throw only non-400 errors
      if (error.response?.status !== 400) {
        throw error;
      }
    },
  });
}
