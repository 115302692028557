import { ExpertNextStepCallout } from "@/components/organisms/UserCallouts/ExpertNextStepCallout";
import { useExpertReadiness } from "@/hooks/use-expert";

export function ExpertOnboardingCallout() {
  const { hasSoftware, hasIndustry, hasDocument, isProfileSetupDone } =
    useExpertReadiness();

  const completedAllSteps =
    hasDocument && hasIndustry && hasSoftware && isProfileSetupDone;

  if (completedAllSteps) return null;

  return (
    <div className="w-dvw lg:hidden">
      <div className="m-6 mt-0 rounded-lg border border-[#0065F2] border-solid">
        <ExpertNextStepCallout
          hasDocument={hasDocument}
          hasIndustry={hasIndustry}
          hasSoftware={hasSoftware}
          isProfileSetupDone={isProfileSetupDone}
        />
      </div>
    </div>
  );
}
