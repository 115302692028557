import { Stack, Text, Title } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import { useEffect } from "react";

import { DocumentStatus } from "@/components/molecules/DocumentStatus/DocumentStatus";
import { useAuth } from "@/hooks/use-auth";
import type { SDocument } from "types";
import { UserProfile } from "../UserProfile/UserProfile";
import * as styles from "./css/Document.module.css";
import { BestPracticeDocumentRenderer } from "./renderer/BestPracticeDocumentRenderer";
import { SoftwareDocumentRenderer } from "./renderer/SoftwareDocumentRenderer";
import {
  isSoftwareDocument,
  sendDocumentViewEvent,
  sendExpertViewEvent,
  sendLinkPreviewClickEvent,
  sendSoftwareRecommendationClickEvent,
} from "./utils";

function Feedback({ message }: { message: string }) {
  return (
    <Stack p="md" className="rounded bg-[--mantine-color-orange-0]">
      <Title order={5}>Feedback from GuideStack admins</Title>
      <Text>{message}</Text>
    </Stack>
  );
}

type NonPublishedDocumentProps = {
  document: SDocument;
  className?: string;
};

export function NonPublishedDocument({
  document,
  className,
}: NonPublishedDocumentProps) {
  const { user } = useAuth();

  useEffect(() => sendDocumentViewEvent({ document, user }), [document, user]);

  return (
    <Stack component="article" className={clsx(styles.document, className)}>
      <span className="font-semibold text-2xl/normal">
        {document.content.prompts[0]?.title}
      </span>
      <DocumentStatus document={document} />
      {document.declineFeedback && (
        <Feedback message={document.declineFeedback} />
      )}
      <UserProfile
        user={document.author}
        showFullProfileOnClick={false}
        onExpertView={() =>
          sendExpertViewEvent({
            document,
            user,
          })
        }
      />
      {isSoftwareDocument(document) ? (
        <SoftwareDocumentRenderer
          document={document}
          onLinkPreviewClick={(url) =>
            sendLinkPreviewClickEvent({
              document,
              user,
              url,
            })
          }
        />
      ) : (
        <Stack>
          {document.content?.prompts.map((content) => (
            <BestPracticeDocumentRenderer
              key={content.id}
              content={content}
              currentTitleOrder={1}
              onLinkPreviewClick={(url) =>
                sendLinkPreviewClickEvent({
                  document,
                  user,
                  url,
                })
              }
              onSoftwareRecommendationClick={(software) =>
                sendSoftwareRecommendationClickEvent({
                  document,
                  user,
                  software,
                })
              }
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
