import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";

import type { SDocument } from "types";
import { documentQueryKey } from "./use-documents";

type DocumentContentUpdateVariables = {
  documentId: string;
  documentContentPartial: SDocument["content"];
};

export function updateDocumentUrl(documentId: string) {
  return `/documents/${documentId}`;
}

export function useDocumentContentUpdate() {
  const queryClient = useQueryClient();

  return useMutation<SDocument, AxiosError, DocumentContentUpdateVariables>({
    mutationFn: (variables) => {
      return axios.patch(updateDocumentUrl(variables.documentId), {
        content: variables.documentContentPartial,
      });
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [documentQueryKey.document, variables.documentId],
      });
    },
  });
}

export function submitDocumentUrl(documentId: string) {
  return `/documents/${documentId}/submit-for-review`;
}

export function useDocumentSubmission() {
  return useMutation({
    mutationFn: (variables: { documentId: string }) =>
      axios.post<SDocument>(submitDocumentUrl(variables.documentId)),
  });
}

export function deleteDocumentUrl(documentId: string) {
  return `/documents/${documentId}`;
}

export function useDocumentDeletion({ isAdminView }: { isAdminView: boolean }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { documentId: string }) =>
      axios.delete<SDocument>(deleteDocumentUrl(variables.documentId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [documentQueryKey.userList, { isAdminView }],
      });
    },
  });
}

export function moveToDraftDocumentUrl(documentId: string) {
  return `/documents/${documentId}/move-to-draft`;
}

export function useDocumentMoveToDraft({
  isAdminView,
}: { isAdminView: boolean }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { documentId: string }) =>
      axios.post<SDocument>(moveToDraftDocumentUrl(variables.documentId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["userDocs", { isAdminView }],
      });
    },
  });
}

export function publishDocumentUrl(documentId: string) {
  return `/documents/${documentId}/publish`;
}

export function useDocumentPublish({ isAdminView }: { isAdminView: boolean }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { documentId: string }) =>
      axios.post<SDocument>(publishDocumentUrl(variables.documentId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["userDocs", { isAdminView }],
      });
    },
  });
}

export function declineDocumentUrl(documentId: string) {
  return `/documents/${documentId}/decline`;
}

export function useDocumentDecline({ isAdminView }: { isAdminView: boolean }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { documentId: string; feedback: string }) =>
      axios.post<SDocument>(declineDocumentUrl(variables.documentId), {
        feedback: variables.feedback,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["userDocs", { isAdminView }],
      });
    },
  });
}
