import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";

import { useAuth } from "@/hooks/use-auth";
import { useDocumentPublish } from "@/hooks/use-document-mutation";

type Props = {
  id: string;
  opened: boolean;
  onPublish: () => void;
  onCancel: () => void;
};

export function PublishDocumentDialog(props: Props) {
  const { role } = useAuth();
  const { mutateAsync, isPending, isError } = useDocumentPublish({
    isAdminView: role === "admin",
  });

  return (
    <Modal
      opened={props.opened}
      onClose={props.onCancel}
      title="Publish this document?"
    >
      <Stack>
        <Text>
          Once published, this prompt and document will be visible to all
          platform users.
        </Text>
        {isError && (
          <Text className="text-[--mantine-color-error]">
            Failed to publish document.
          </Text>
        )}
        <Flex className="flex-col lg:flex-row-reverse">
          <Button
            loading={isPending}
            disabled={!props.opened}
            onClick={async () => {
              await mutateAsync({ documentId: props.id });
              props.onPublish();
            }}
          >
            Publish document
          </Button>
          <Button
            variant="outline"
            disabled={isPending || !props.opened}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}
