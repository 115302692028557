import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { isAfter, isToday, startOfDay, subDays } from "date-fns";

import type { NotificationDto } from "types";

export function getNotificationsUrl() {
  return "/notifications";
}

export function getNotificationsCountUrl() {
  return "/notifications/check-new";
}

export function useNotificationCount(interval = 30000) {
  return useQuery({
    queryKey: ["notificationCount"],
    queryFn: async () => {
      const { data } = await axios.get<{
        lastRead: string;
        newNotificationCount: number;
      }>(getNotificationsCountUrl());

      return data;
    },
    refetchInterval: interval,
  });
}

export function useNotifications() {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const { data } = await axios.get<NotificationDto[]>(
        getNotificationsUrl(),
      );

      return {
        notifications: groupNotifications(data),
        hasNotifications: data?.length > 0,
      };
    },
  });
}

export type GroupedNotifications = {
  today: NotificationDto[];
  lastSevenDays: NotificationDto[];
  older: NotificationDto[];
};

export function groupNotifications(
  notifications: NotificationDto[],
): GroupedNotifications {
  const now = new Date();
  const today = startOfDay(now);
  const sevenDaysAgo = subDays(today, 7);

  return notifications.reduce(
    (acc: GroupedNotifications, notification) => {
      const createdAt = new Date(notification.createdAt);

      if (isToday(createdAt)) {
        acc.today.push(notification);
      } else if (isAfter(createdAt, sevenDaysAgo)) {
        acc.lastSevenDays.push(notification);
      } else {
        acc.older.push(notification);
      }

      return acc;
    },
    { today: [], lastSevenDays: [], older: [] },
  );
}
