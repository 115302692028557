import {
  faBars,
  faChevronDown,
  faClose,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Anchor,
  Avatar,
  Button,
  Container,
  Drawer,
  Group,
  Menu,
  Tabs,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { clsx } from "clsx/lite";
import { useState } from "react";
import {
  Link,
  type To,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Logo } from "@/components/atoms/Logo/Logo";
import { useAuth } from "@/hooks/use-auth";
import { useNotificationCount } from "@/hooks/use-notifications";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { useScreenSize } from "@/hooks/use-screen-size";
import { paths } from "@/routes/paths";
import { UnreadSpot } from "@/screens/notifications/NotificationItem";
import { useFeatureFlag } from "@/third-party/amplitude-experiments";
import { faBell } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { UserMenu } from "../UserMenu/UserMenu";

function getNavItems({
  isLoggedIn,
  expertFirst = false,
}: { isLoggedIn: boolean; expertFirst?: boolean }) {
  const items = [
    {
      label: "Guides",
      to: paths.documentation.root,
    },
    {
      label: "Experts",
      to: paths.experts.root,
    },
  ];

  if (expertFirst) {
    items.reverse();
  }

  items.push({
    label: "Sessions",
    to: isLoggedIn ? paths.sessions.root : paths.sessions.preview,
  });

  return items;
}

/**
 * If the pathname is a subpath of a navigation destination, return the base path
 * It is used to highlight the active tab in the navigation bar
 * @param pathname
 */
function getPathNameBase(
  pathname: string,
  { isLoggedIn = false }: { isLoggedIn: boolean },
) {
  return (
    getNavItems({ isLoggedIn })
      .map((destination) => matchPath(`${destination.to}/*`, pathname))
      .find(Boolean)?.pathnameBase || "/"
  );
}

type NavigationDrawerProps = {
  onOpen?: () => void;
  onClose?: () => void;
};

function NavigationDrawer(props: NavigationDrawerProps) {
  const [opened, handlers] = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();

  const { isOn: isExpertFirstOn } = useFeatureFlag(
    "top-nav-expert-directory-first",
  );

  const { user } = useAuth();
  const isLoggedIn = !!user;

  return (
    <>
      <Button
        variant="outline"
        aria-label="navigation drawer"
        className={clsx(
          "size-6 h-auto border-none p-1",
          !opened && "text-[--mantine-color-gray-7]",
        )}
        onClick={() => {
          handlers.toggle();
          props.onOpen?.();
        }}
      >
        <FontAwesomeIcon icon={opened ? faClose : faBars} />
      </Button>
      <Drawer
        position="top"
        opened={opened}
        onClose={handlers.toggle}
        classNames={{
          content: "h-auto rounded-t-none mt-16 p-0",
          body: "px-6 py-4",
        }}
        onTransitionEnd={() => {
          if (!opened) {
            props.onClose?.();
          }
        }}
      >
        <Tabs
          value={getPathNameBase(location.pathname, { isLoggedIn })}
          orientation="vertical"
          withUnderline={false}
          onChange={(pathname) => {
            navigate(pathname as To);
            handlers.toggle();
          }}
        >
          <Tabs.List className="!gap-2 w-full">
            {getNavItems({ isLoggedIn, expertFirst: isExpertFirstOn }).map(
              (destination) => (
                <Tabs.Tab
                  key={destination.to}
                  value={destination.to}
                  className="data-[active=true]:!font-normal h-10 px-2 data-[active=true]:bg-[--mantine-primary-color-0] data-[active=true]:text-[--mantine-color-text]"
                >
                  {destination.label}
                </Tabs.Tab>
              ),
            )}
          </Tabs.List>
        </Tabs>
      </Drawer>
    </>
  );
}

type NavigationBarProps = {
  className?: string;
};

function NavigationBar(props: NavigationBarProps) {
  const { user, role, setRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLg } = useScreenSize();

  const { isOn: isExpertFirstOn } = useFeatureFlag(
    "top-nav-expert-directory-first",
  );

  const [isOnTop, setIsOnTop] = useState(false);
  const isLoggedIn = !!user;

  return (
    // please verify if the rich text editor (TineMCE)'s sticky toolbar works fine
    // if any changes that may affect the height of the navigation bar are made
    <header
      className={clsx(
        "relative bg-white lg:shadow-paper",
        isOnTop && "z-[999]", // When the drawer is opened, it should be on top
        props.className,
      )}
    >
      <Container component="nav" className="max-w-full px-4 py-0">
        <Group className="h-[4.5rem] justify-between gap-2 align-center lg:gap-4">
          <Group className="gap-2 align-center lg:gap-4">
            {!isLg && (
              <NavigationDrawer
                onOpen={() => setIsOnTop(true)}
                onClose={() => setIsOnTop(false)}
              />
            )}
            <Link
              to={isLoggedIn ? paths.root : paths.external.landing}
              className="flex"
            >
              <Logo />
            </Link>
            {isLg && (
              <Tabs
                value={getPathNameBase(location.pathname, { isLoggedIn })}
                onChange={(pathname) => navigate(pathname as To)}
              >
                <Tabs.List>
                  {getNavItems({
                    isLoggedIn,
                    expertFirst: isExpertFirstOn,
                  }).map((destination) => (
                    <Tabs.Tab
                      key={destination.to}
                      value={destination.to}
                      className="-mb-[1.35rem] rounded-none p-0 pb-[1.15rem] font-medium text-xl/normal data-[active=true]:font-bold data-[active=true]:text-[--mantine-color-text]"
                    >
                      {destination.label}
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
              </Tabs>
            )}
          </Group>
          {user?.isAdmin && (
            <Menu>
              <Menu.Target>
                <Button
                  variant="transparent"
                  aria-label={`Switch to ${
                    role === "admin" ? "Customer" : "Admin"
                  } view`}
                  className="ml-auto px-0"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {role === "admin" ? "Admin" : "Customer"}
                  <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => setRole("admin")}>Admin</Menu.Item>
                <Menu.Item onClick={() => setRole("customer")}>
                  Customer
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
          {user ? (
            <div className="flex items-center gap-3">
              <NotificationIcon />
              <UserMenu />
            </div>
          ) : (
            <SignUpButton />
          )}
        </Group>
      </Container>
    </header>
  );
}

function NotificationIcon() {
  const { data } = useNotificationCount();

  const showSpot = Boolean(data?.newNotificationCount);

  return (
    <Anchor
      component={Link}
      to={paths.notifications}
      aria-label="Notifications"
      className="relative size-6 text-center align-middle text-[--mantine-color-gray-6]"
    >
      <FontAwesomeIcon icon={faBell} className="size-4" />
      {showSpot && (
        <div className="absolute top-0 right-1 size-2 leading-none">
          <UnreadSpot />
        </div>
      )}
    </Anchor>
  );
}

function SignUpButton() {
  const { openSignUpModal } = useSignUpSearchParams();

  return (
    <div className="hidden gap-4 sm:flex">
      <Button onClick={openSignUpModal} variant="primary">
        Join GuideStack
      </Button>
      <Avatar />
    </div>
  );
}

export { NavigationBar };
