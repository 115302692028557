import {
  Button,
  Group,
  SimpleGrid,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { ExpertIndustryForm } from "@/components/organisms/ExpertIndustryForm/ExpertIndustryForm";
import { useExpertIndustries } from "@/hooks/use-expert";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";
import * as styles from "./Profile.module.css";

const ExpertIndustry = () => {
  const { data: industries, refetch } = useExpertIndustries();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("industry"));

  if (!industries) return null;

  const hasContents = !!industries.length;

  const onClose = () => {
    handlers.close();
    // TODO: A better way to handle this is to invalidate the query cache
    // when ExpertIndustryForm is submitted
    refetch();
  };

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={2} className={styles.title}>
          Industry expertise
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ExpertOwnProfileEvent.name,
              ExpertOwnProfileEvent.properties.editIndustries,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {hasContents && (
        <>
          <Text>
            You’ve added {industries.length} industr
            {industries.length > 1 ? "ies" : "y"} to your expertise.
          </Text>
          <SimpleGrid cols={{ base: 1, lg: 4 }} className={styles.simpleGrid}>
            {industries.map((industry) => (
              <div key={industry.id} className={styles.item}>
                <Text span lineClamp={1}>
                  {industry.name}
                </Text>
              </div>
            ))}
          </SimpleGrid>
        </>
      )}
      {!hasContents && (
        <Text className={styles.secondaryText}>
          Please add the industries you have expertise in. This information will
          help us request relevant documents and match you with potential
          clients.
        </Text>
      )}
      <Drawer
        opened={opened}
        onClose={onClose}
        title="Add your industry expertise"
      >
        <ExpertIndustryForm onClose={onClose} withStickyControls />
      </Drawer>
    </>
  );
};

export { ExpertIndustry };
