import { Stack, Title } from "@flpstudio/design-system";
import parseHtml from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";
import { Fragment } from "react";

import { LinkPreview } from "@/components/atoms/LinkPreview/LinkPreview";
import type { SDocument } from "types";
import styles from "../css/Richtext.module.css";

type SoftwareDocumentRendererProps = {
  document: SDocument;
  onLinkPreviewClick?: (url: string) => void;
};

export function SoftwareDocumentRenderer(props: SoftwareDocumentRendererProps) {
  /**
   * At the moment, we need to skip displaying the first level of subPrompts and display the second level directly (Product request details).
   * This is a temporary solution, and we will flatten the hierarchy in the future.
   * {
   *   props.content: {
   *     id: "string"
   *     title: "string"
   *     subPrompts: [{
   *       id: "string",    \
   *       title: "string",  > Skip displaying these using .flatMap since they are no longer displayed
   *       value: "string", /
   *       subPrompts: [{
   *         id: "string",
   *         title: "string",
   *         value: "string",
   *       }],
   *     }]
   *   }
   * }
   */
  const prompts = [];
  const links = [];

  const subPrompts = (
    props.document.content.prompts[0]?.subPrompts || []
  ).flatMap((prompt) => prompt.subPrompts);

  for (const prompt of subPrompts) {
    if (prompt?.value) {
      prompts.push({
        id: prompt.id,
        title: prompt.title,
        value: prompt.value,
      });
    }

    if (prompt?.metadata?.relevantArticles?.value?.length) {
      links.push(...prompt.metadata.relevantArticles.value);
    }
  }

  return (
    <Stack>
      <Title order={1} className="sr-only">
        {props.document.content.prompts[0]?.title}
      </Title>
      {prompts.map((prompt) => (
        <Fragment key={prompt?.id}>
          <Title order={2}>{prompt?.title}</Title>
          <div className={styles.richText}>
            {parseHtml(DOMPurify.sanitize(prompt?.value || ""))}
          </div>
        </Fragment>
      ))}
      {links.length > 0 && (
        <>
          <Title order={2}>Relevant external links</Title>
          <ul className="m-0 flex list-none flex-col gap-4 p-0">
            {links.map((link) => (
              <li key={link.url} className="flex gap-2">
                <LinkPreview
                  href={link.url}
                  title={link.title}
                  description={link.description}
                  favicon={link.favicon}
                  onClick={() => props.onLinkPreviewClick?.(link.url)}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </Stack>
  );
}
