import { createRoot } from "react-dom/client";

import { App, appRoot } from "./App";
import { amplitudeExperiments } from "./third-party/amplitude-experiments";

async function initApp() {
  await amplitudeExperiments.init();

  createRoot(appRoot, {
    identifierPrefix: "streamline-", // For ID (HTML id) generation
  }).render(<App />);
}

initApp();
