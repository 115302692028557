import { Badge } from "@flpstudio/design-system";

type UserType = "author" | "me" | "expert";

type UserBadgeProps = {
  userType: UserType | null;
  className?: string;
};

export function UserBadge({ userType, className }: UserBadgeProps) {
  let text = "";
  let color = "";
  switch (userType) {
    case "me":
      text = "You";
      color = "green";
      break;
    // a user can be both an author and an expert, so we prioritize the author
    case "author":
      text = "Author";
      break;
    case "expert":
      text = "Expert";
      color = "blue";
      break;
    default:
      return null;
  }

  return (
    <Badge className={className} color={color} variant="light">
      {text}
    </Badge>
  );
}
