import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";

import { httpClient } from "@/api/client";
import type {
  CalendlyNewBooking,
  ExpertBookingConfigurationForClientDto,
} from "@/api/openapi";

export const bookingQueryKey = {
  advisoryBooking: "advisoryBooking",
  introBooking: "introBooking",
  bookingList: "bookingList",
  session: "session",
};

type UseAvailableIntroBookingVariables = {
  identifier: string;
};

export function useAvailableIntroBooking({
  identifier,
}: UseAvailableIntroBookingVariables) {
  return useQuery<ExpertBookingConfigurationForClientDto>({
    queryKey: [bookingQueryKey.introBooking, identifier],
    queryFn: async () => {
      const { data } = await httpClient.paths[
        "/v1/experts/{identifier}/intro-booking-configs"
      ].get({
        identifier,
      });
      return data;
    },
    staleTime: 0,
    enabled: !!identifier,
    // If it returns 404, it means the expert is not available for booking
    // so retrying is not necessary
    retry: false,
  });
}

// There can only be one AvailableIntroBooking per expert
// But invalidating queries does not remove the query from the cache when the backend returns 404
// So we need to remove the query manually
export function useRemoveAvailableIntroBooking({
  identifier,
}: UseAvailableIntroBookingVariables) {
  const queryClient = useQueryClient();

  return () =>
    queryClient.removeQueries({
      queryKey: [bookingQueryKey.introBooking, identifier],
    });
}

type UseAvailableAdvisoryBookingVariables = {
  identifier: string;
};

export function useAvailableAdvisorySessionBooking({
  identifier,
}: UseAvailableAdvisoryBookingVariables) {
  return useQuery<ExpertBookingConfigurationForClientDto>({
    queryKey: [bookingQueryKey.advisoryBooking, identifier],
    queryFn: async () => {
      const { data } = await httpClient.paths[
        "/v1/experts/{identifier}/booking-configs"
      ].get({
        identifier,
      });
      return data;
    },
    enabled: !!identifier,
    // If it returns 404, it means the expert is not available for booking
    // so retrying is not necessary
    retry: false,
  });
}

type UseAvailableAdvisorySessionByExpertListVariables = {
  identifierList: string[];
};

// Fetch a list of AvailableAdvisorySessions by their expert identifiers
export function useAvailableAdvisorySessionsByExpertList({
  identifierList,
}: UseAvailableAdvisorySessionByExpertListVariables) {
  return useQueries({
    queries: identifierList.map((identifier) => ({
      queryKey: [bookingQueryKey.advisoryBooking, identifier],
      queryFn: async () => {
        const { data } = await httpClient.paths[
          "/v1/experts/{identifier}/booking-configs"
        ].get({ identifier });
        return data;
      },
    })),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isLoading: results.some((result) => result.isLoading),
    }),
  });
}

export const BOOKING_LIST_URL = "/v1/booking-sessions";

export function useBookingList({ enabled }: { enabled?: boolean }) {
  return useQuery({
    queryKey: [bookingQueryKey.bookingList],
    queryFn: async () => {
      const { data } = await httpClient.paths[BOOKING_LIST_URL].get();
      return data;
    },
    enabled,
  });
}

export const NEW_BOOKING_URL = "/v1/calendly/new-booking";

export function useNewBookedSession(variables?: CalendlyNewBooking) {
  return useQuery({
    queryKey: [bookingQueryKey.session, variables?.eventUri],
    queryFn: async () => {
      const { data } = await httpClient.paths[NEW_BOOKING_URL].post(
        null,
        variables,
      );
      return data;
    },
    enabled: !!variables,
  });
}
