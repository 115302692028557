import { faCircleExclamation } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Group,
  Paper,
  Stack,
  Text,
  Title,
  type TitleProps,
  VisuallyHidden,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Fragment,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { RichTextEditor } from "@/components/molecules/RichTextEditor/RichTextEditor";
import { SoftwareList } from "@/components/organisms/SoftwareList/SoftwareList";
import { useDocumentContentUpdate } from "@/hooks/use-document-mutation";
import {
  Amplitude,
  DocumentCreationEvent,
  createDocumentProperties,
} from "@/third-party/amplitude";
import type {
  SDocument,
  SDocumentContentPrompt,
  SDocumentPromptMetadata,
} from "types";
import * as styles from "./EditDocumentForm.module.css";
import { EditLinkPreviewDialog } from "./EditLinkPreviewDialog";
import { EditLinkPreviewList } from "./EditLinkPreviewList";
import { type FormSchema, promptFullSchema } from "./validator";

type Props = {
  name: string;
  document: SDocument;
  onFormStatusChange?: (formStatus: {
    isSaving: boolean;
    isSavingForSubmitSuccess?: boolean;
  }) => void;
  className?: string;
};

export const FORM_ID = "EditDocumentForm";
export const FORM_INTENT_SAVE = "formIntentSave";
export const FORM_INTENT_SUBMIT = "formIntentSubmit";

export function EditDocumentForm(props: Props) {
  const { control, formState, getValues, reset, setValue, trigger } =
    useForm<FormSchema>({
      resolver: async (prompt, ...args) => {
        /**
         * NOTE: Due to having a recursive schema and use of `useFieldArray`,
         * the `prompt` object will have this structure:
         * [
         *  {
         *    id: <missing>, <=== This field is required
         *    title: <missing>, <=== This field is required
         *    subPrompts: [{
         *      id: "some-id",
         *      title: "Some title",
         *      description: "Some description",
         *      ...
         *    }]
         *  }
         * ]
         *
         * The missing `id` and `title` fields will not pass the validation.
         * So we need to "fill in" these fields with empty strings.
         */
        prompt.id = "";
        prompt.title = "";

        return await zodResolver(promptFullSchema)(prompt, ...args);
      },
      reValidateMode: "onBlur",
    });

  const { fields: prompts } = useFieldArray({
    control,
    name: "subPrompts",
    keyName: "fieldId",
  });

  const [selectedLinkPreview, setSelectedLinkPreview] = useState<{
    fieldName: string;
    metadataIndex: number;
  } | null>(null);

  useEffect(() => {
    if (props.document) {
      /**
       * React Query re-fetches the values on tab focus,
       * so it will almost guarantee that the `props.document` will have the latest values.
       */
      reset(
        { subPrompts: props.document.content?.prompts || [] },
        {
          keepErrors: true,
        },
      );
    }
  }, [props.document, reset]);

  const { mutateAsync, isPending } = useDocumentContentUpdate();

  const submitHandler = useCallback(
    async (e: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();
      props.onFormStatusChange?.({ isSaving: true });

      const intent = (e.nativeEvent.submitter as HTMLButtonElement).value;

      if (intent === FORM_INTENT_SAVE && (!formState.isDirty || isPending)) {
        props.onFormStatusChange?.({ isSaving: false });
        return;
      }

      if (intent === FORM_INTENT_SUBMIT) {
        const isFormValid = await trigger();
        if (!isFormValid) {
          props.onFormStatusChange?.({ isSaving: false });
          return;
        }
      }

      const documentContentPartial = { prompts: getValues("subPrompts") || [] };

      try {
        await mutateAsync({
          documentId: props.document.id,
          documentContentPartial,
        });
      } finally {
        props.onFormStatusChange?.({ isSaving: false });
      }

      sendEvent(intent, { ...props.document, content: documentContentPartial });

      props.onFormStatusChange?.({
        isSaving: false,
        isSavingForSubmitSuccess: intent === FORM_INTENT_SUBMIT,
      });
    },
    [formState.isDirty, getValues, mutateAsync, props, trigger, isPending],
  );

  return (
    <div className={props.className}>
      <form
        onSubmit={submitHandler}
        id={FORM_ID}
        name={props.name}
        className={styles.form}
      >
        <Stack>
          <VisuallyHidden component="h1">{prompts[0]?.title}</VisuallyHidden>
          {Object.keys(formState.errors).length > 0 && (
            <Paper className="border border-[--mantine-color-error] border-solid">
              <Group>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className="text-[--mantine-color-error]"
                />
                <Text className="font-semibold">
                  Please fix the errors below
                </Text>
              </Group>
            </Paper>
          )}
          {prompts[0]?.subPrompts?.map((prompt, promptIndex) => (
            <Paper key={prompt.id} className="-mx-6 lg:mx-0">
              <Stack>
                {(() => {
                  let headingOrder = 2;
                  const nodes: ReactNode[] = [];

                  const renderNodes = (
                    promptProperties: SDocumentContentPrompt,
                    formFieldNamePrefix: string,
                  ) => {
                    nodes.push(
                      <Fragment key={formFieldNamePrefix}>
                        <Group gap={8}>
                          <Title order={headingOrder as TitleProps["order"]}>
                            {promptProperties.title}
                          </Title>
                          {Object.hasOwn(promptProperties, "required") &&
                            !promptProperties.required && (
                              <span className="rounded bg-[--mantine-color-gray-2] px-1 py-[0.125rem] text-[--mantine-color-gray-7] text-xs/normal">
                                Optional
                              </span>
                            )}
                        </Group>
                        {promptProperties.description && (
                          <Text>{promptProperties.description}</Text>
                        )}
                        {Object.hasOwn(promptProperties, "value") && (
                          <Controller
                            control={control}
                            // @ts-ignore TypeScript has trouble inferring the dynamic field name
                            name={`${formFieldNamePrefix}.value`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore TypeScript has trouble inferring the dynamic value prop
                              <RichTextEditor
                                {...field}
                                // Heading order should be lower than the current prompt heading
                                headingOrder={headingOrder + 1}
                                error={fieldState.error?.message}
                                onSave={() => {
                                  /* noop Stops webpage from reloading when Ctrl/Cmd + S is pressed */
                                }}
                              />
                            )}
                          />
                        )}
                        {promptProperties.metadata?.relevantArticles && (
                          <Controller
                            control={control}
                            // @ts-ignore TypeScript has trouble inferring the dynamic field name
                            name={`${formFieldNamePrefix}.metadata.relevantArticles.value`}
                            render={({ field, fieldState }) => (
                              <EditLinkPreviewList
                                {...field}
                                label="Relevant external links"
                                placeholder="Add relevant articles"
                                error={fieldState.error?.message}
                                required={
                                  promptProperties.metadata?.relevantArticles
                                    ?.required
                                }
                                onLinkPreviewClick={(metadataIndex) => {
                                  setSelectedLinkPreview({
                                    // @ts-ignore TypeScript crashes when trying to infer the dynamic field name
                                    fieldName: field.name, // Point to which instance of EditLinkPreviewList this metadata belongs to
                                    metadataIndex,
                                  });
                                }}
                              />
                            )}
                          />
                        )}
                        {promptProperties.metadata?.recommendations && (
                          <Controller
                            control={control}
                            // @ts-ignore TypeScript has trouble inferring the dynamic field name
                            name={`${formFieldNamePrefix}.metadata.recommendations.value`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore TypeScript has trouble inferring the dynamic value prop
                              <SoftwareList
                                {...field}
                                label="Recommended software"
                                placeholder="Type to search, then select"
                                error={fieldState.error?.message}
                                required={
                                  promptProperties.metadata?.recommendations
                                    ?.required
                                }
                              />
                            )}
                          />
                        )}
                      </Fragment>,
                    );
                    if (promptProperties.subPrompts) {
                      headingOrder++;
                      promptProperties.subPrompts.forEach(
                        (subPrompt, subPromptIndex) => {
                          renderNodes(
                            subPrompt,
                            `${formFieldNamePrefix}.subPrompts.${subPromptIndex}`,
                          );
                        },
                      );
                    }
                  };

                  renderNodes(prompt, `subPrompts.0.subPrompts.${promptIndex}`);

                  return nodes;
                })()}
              </Stack>
            </Paper>
          ))}
        </Stack>
      </form>
      <EditLinkPreviewDialog
        metadataArray={
          // @ts-ignore TypeScript crashes when trying to infer the dynamic field name
          selectedLinkPreview && getValues(selectedLinkPreview?.fieldName)
        }
        metadataIndex={selectedLinkPreview?.metadataIndex}
        onSubmit={(updatedMetadata) => {
          const linkArray = [
            ...(getValues(
              // biome-ignore lint/suspicious/noExplicitAny: TypeScript crashes when trying to infer the dynamic field name
              selectedLinkPreview?.fieldName as any,
            ) as SDocumentPromptMetadata[]),
          ];

          linkArray[selectedLinkPreview?.metadataIndex ?? -1] = updatedMetadata;

          // @ts-ignore TypeScript has trouble inferring the dynamic field name
          setValue(selectedLinkPreview?.fieldName, linkArray, {
            shouldDirty: true,
          });

          setSelectedLinkPreview(null);
        }}
        onClose={() => {
          setSelectedLinkPreview(null);
        }}
      />
    </div>
  );
}

function sendEvent(intent: string, updatedDocument: SDocument) {
  let action = "";
  if (intent === FORM_INTENT_SUBMIT) {
    action = DocumentCreationEvent.properties.clickSubmit.action;
  } else if (intent === FORM_INTENT_SAVE) {
    action = DocumentCreationEvent.properties.clickSave.action;
  }

  if (!action) {
    return;
  }

  Amplitude.track(DocumentCreationEvent.name, {
    action,
    // The updatedDocumentPartial does not contain the full document data needed for the event
    // So we merge the updatedPartial with the original document data
    ...createDocumentProperties(updatedDocument),
  });
}
