import { faLink } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Button,
  Flex,
  Group,
  Modal,
  Text,
  Title,
  Tooltip,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";

import {
  EXPERT_PROFILE_FORM_ID,
  ExpertProfileForm,
} from "@/components/organisms/ExpertProfileForm/ExpertProfileForm";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "@/routes/paths";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";
import { isExpertProfileCompleted } from "@/utils/user-profile";
import { generatePath } from "react-router-dom";
import * as styles from "./Profile.module.css";

const ExpertProfile = () => {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("myProfile"));
  const [isLinkCopied, setLinkCopied] = useState(false);
  const isComplete = isExpertProfileCompleted(user);

  if (!user) return null;

  const cancelUpdate = () => {
    handlers.close();
    Amplitude.track(
      ExpertOwnProfileEvent.name,
      ExpertOwnProfileEvent.properties.profileModalClose,
    );
  };

  const shareableLink = generatePath(paths.experts.profile, {
    identifier: user.urlId,
  });

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={1} className={styles.title}>
          My profile
        </Title>
        <div className="flex gap-4">
          <Tooltip
            position="top"
            classNames={{
              tooltip: "text-sm",
            }}
            label="Link copied!"
            opened={isLinkCopied}
          >
            <Button
              variant="outline"
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${window.location.origin}${shareableLink}`,
                );
                setLinkCopied(true);
                setTimeout(() => setLinkCopied(false), 2000);
              }}
            >
              Share public profile
              <FontAwesomeIcon className="ml-1" icon={faLink} />
            </Button>
          </Tooltip>
          <Button
            variant="outline"
            onClick={() => {
              handlers.open();
              Amplitude.track(
                ExpertOwnProfileEvent.name,
                ExpertOwnProfileEvent.properties.editProfile,
              );
            }}
          >
            Edit
          </Button>
        </div>
      </Group>
      {isComplete && (
        <>
          <UserProfile
            user={user}
            className="mb-4"
            showFullProfileOnClick={false}
          />
          <Text>{user?.expertProfile?.summary}</Text>
        </>
      )}
      {!isComplete && (
        <Text className={styles.secondaryText}>
          Please add your profile information.
        </Text>
      )}
      <Modal opened={opened} onClose={cancelUpdate} title="Edit profile">
        <ExpertProfileForm
          onSubmit={handlers.close}
          actionButtonGroup={
            <Flex className="-mb-6 sticky bottom-0 z-10 flex-col bg-white pb-6 lg:flex-row-reverse">
              <Button type="submit" form={EXPERT_PROFILE_FORM_ID}>
                Submit
              </Button>
              <Button variant="outline" onClick={cancelUpdate}>
                Cancel
              </Button>
            </Flex>
          }
        />
      </Modal>
    </>
  );
};

export { ExpertProfile };
