import {
  Button,
  Flex,
  Modal,
  Stack,
  TextInput,
} from "@flpstudio/design-system";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import type { SDocumentPromptMetadata } from "types";

const STRING_MIN_LENGTH = 1;
const STRING_MAX_LENGTH = 256;

const TITLE_MIN_LENGTH_MESSAGE = `Title should be more than ${STRING_MIN_LENGTH} character`;
const TITLE_MAX_LENGTH_MESSAGE = `Title should be ${STRING_MAX_LENGTH} characters or less`;
const DESCRIPTION_MIN_LENGTH_MESSAGE = `Description should be more than ${STRING_MIN_LENGTH} character`;
const DESCRIPTION_MAX_LENGTH_MESSAGE = `Description should be ${STRING_MAX_LENGTH} characters or less`;

function generateSchema(metadataUrlArray: string[] = [], metadataIndex = -1) {
  return validator.object({
    url: validator
      .string()
      .trim()
      .toLowerCase()
      .url("URL is not valid")
      .refine(
        (url) => {
          const index = metadataUrlArray.indexOf(url);
          /**
           * Valid only if the provided URL is
           * 1. Not in the metadataUrlArray already
           * 2. In metadataUrlArray already, but in the same index as the metadata being edited
           */
          return index === -1 || index === metadataIndex;
        },
        {
          message: "URL already added",
        },
      ),
    favicon: validator.string(),
    title: validator
      .string()
      .trim()
      .min(STRING_MIN_LENGTH, TITLE_MIN_LENGTH_MESSAGE)
      .max(STRING_MAX_LENGTH, TITLE_MAX_LENGTH_MESSAGE),
    description: validator
      .string()
      .trim()
      .min(STRING_MIN_LENGTH, DESCRIPTION_MIN_LENGTH_MESSAGE)
      .max(STRING_MAX_LENGTH, DESCRIPTION_MAX_LENGTH_MESSAGE),
  });
}

type Props = {
  /**
   * Array of metadata currently in the EditLinkPreviewList that is being edited
   */
  metadataArray?: SDocumentPromptMetadata[];
  /**
   * Index of the metadata to be edited in the metadata list
   */
  metadataIndex?: number;
  onSubmit: (metadata: SDocumentPromptMetadata) => void;
  onClose: () => void;
};

function EditLinkPreviewForm(props: Props) {
  const schema = generateSchema(
    props.metadataArray?.map((metadata) => metadata.url) || [],
    props.metadataIndex,
  );

  type FormSchema = validator.infer<typeof schema>;

  const { formState, handleSubmit, register } =
    useForm<SDocumentPromptMetadata>({
      defaultValues: props.metadataArray?.[props.metadataIndex ?? -1],
      reValidateMode: "onBlur",
      resolver: zodResolver(schema),
    });

  const onSubmit: SubmitHandler<FormSchema> = async (data: FormSchema) => {
    props.onSubmit(data);
  };

  return (
    <form name="editLink" onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <TextInput
          label="URL"
          {...register("url")}
          error={formState.errors.url?.message}
        />
        <TextInput
          label="Page title"
          {...register("title")}
          error={formState.errors.title?.message}
        />
        <TextInput
          label="Page description"
          {...register("description")}
          error={formState.errors.description?.message}
        />
        <Flex className="flex-col lg:flex-row-reverse">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

export function EditLinkPreviewDialog(props: Props) {
  return (
    <Modal
      opened={Number.isSafeInteger(props.metadataIndex)}
      onClose={props.onClose}
      title="Edit link information"
    >
      <EditLinkPreviewForm {...props} />
    </Modal>
  );
}
