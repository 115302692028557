import { Anchor } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";

type Props = {
  href?: string;
  favicon?: string;
  title?: string;
  description?: string;
  className?: string;
  onClick?: () => void;
};

export function LinkPreview(props: Props) {
  return (
    <Anchor
      href={props.href}
      target="_blank"
      rel="noreferrer noopener nofollow"
      title={props.title}
      className={clsx("flex items-center text-inherit", props.className)}
      onClick={props.onClick}
    >
      {props.favicon && (
        <img src={props.favicon} alt={props.title} className="mr-2 size-5" />
      )}
      <span className="line-clamp-1">
        <span className="mr-2 text-[--mantine-color-gray-7]">
          {props.title}
        </span>
        <span className="font-medium" title={props.description}>
          {props.description}
        </span>
      </span>
    </Anchor>
  );
}
