import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { useIndustries } from "@/hooks/use-industry";
import { CLIENT_SIZE } from "@/utils/client-size";
import type { ClientSize, WorkMode } from "types";
import { WORK_MODE } from "../ClientOnboarding/CompanyInfoForm";

type ClientCompanyProps = {
  size?: ClientSize;
  industryId?: string;
  workMode?: WorkMode;
  summary?: string;
};

export function ClientCompany({
  industryId,
  summary,
  size,
  workMode,
}: ClientCompanyProps) {
  const { data: industryList, isLoading: isLoadingIndustryList } =
    useIndustries();

  const industry = industryList?.find((ind) => ind.id === industryId)?.name;

  return (
    <dl className="m-0">
      {industryId && (
        <>
          <dt>Primary industry</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {isLoadingIndustryList ? <Skeleton /> : industry}
          </dd>
        </>
      )}

      {summary && (
        <>
          <dt>What does your company do?</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {summary}
          </dd>
        </>
      )}

      {size && (
        <>
          <dt>Employee count</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {CLIENT_SIZE.find((s) => s.value === size)?.label}
          </dd>
        </>
      )}

      {workMode && (
        <>
          <dt>Working environment</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {WORK_MODE.find((s) => s.value === workMode)?.label}
          </dd>
        </>
      )}
    </dl>
  );
}
