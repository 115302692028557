import {
  Flex,
  NativeSelect,
  Radio,
  SimpleGrid,
  Stack,
  Text,
  TextArea,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { useIndustries } from "@/hooks/use-industry";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import {
  Amplitude,
  ClientOnboardingEvent,
  SetClientIndustry,
} from "@/third-party/amplitude";
import { CLIENT_SIZE } from "@/utils/client-size";
import type { ClientSize, User, WorkMode } from "types";

const SUMMARY_MIN_LENGTH = 20;
const SUMMARY_MAX_LENGTH = 500;

export const WORK_MODE: { label: string; value: WorkMode }[] = [
  {
    label: "Fully onsite",
    value: "FULLY_ONSITE",
  },
  {
    label: "Hybrid",
    value: "HYBRID",
  },
  {
    label: "Fully remote",
    value: "FULLY_REMOTE",
  },
];

const noSelectionErrorMsg = "Please select an option to proceed";
const schema = validator.object({
  summary: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .min(
      SUMMARY_MIN_LENGTH,
      `Summary should be more than ${SUMMARY_MIN_LENGTH} characters`,
    )
    .max(
      SUMMARY_MAX_LENGTH,
      `Summary should be ${SUMMARY_MAX_LENGTH} characters or less`,
    ),
  size: validator
    .string({ message: noSelectionErrorMsg })
    .min(1, noSelectionErrorMsg),
  workMode: validator
    .string({ message: noSelectionErrorMsg })
    .min(1, noSelectionErrorMsg),
  industryId: validator
    .string({ message: noSelectionErrorMsg })
    .min(1, noSelectionErrorMsg),
});

type FormSchema = validator.infer<typeof schema>;

type Props = {
  onSubmit: () => void;
  actionButtonGroup: React.ReactNode;
};

export function CompanyInfoForm(props: Props) {
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();
  const { data: industryList } = useIndustries();
  const { size, workMode, summary, industryId } =
    user?.clientProfile?.company || {};
  const [selectedIndustry, setSelectedIndustry] = useState(industryId);

  const { formState, getValues, register, handleSubmit, watch } =
    useForm<FormSchema>({
      defaultValues: {
        size: size || "",
        workMode: workMode || "",
        summary: summary || "",
        industryId: industryId || "",
      },
      reValidateMode: "onBlur",
      resolver: zodResolver(schema),
    });

  const summaryLength = watch("summary").length;

  const saveData = () => {
    updateUser({
      clientProfile: {
        ...user?.clientProfile,
        company: {
          ...user?.clientProfile?.company,
          ...(getValues("industryId")
            ? { industryId: getValues("industryId") }
            : {}),
          ...(getValues("size")
            ? { size: getValues("size") as ClientSize }
            : {}),
          ...(getValues("summary") ? { summary: getValues("summary") } : {}),
          ...(getValues("workMode")
            ? { workMode: getValues("workMode") as WorkMode }
            : {}),
        },
      },
    });
  };

  const onSubmit: SubmitHandler<FormSchema> = (formData) => {
    saveData();
    props.onSubmit();

    const clientIndustryName = industryList?.find(
      (industry) => industry.id === formData.industryId,
    )?.name;

    if (clientIndustryName) {
      SetClientIndustry(clientIndustryName);
    }

    Amplitude.track(
      ClientOnboardingEvent.name,
      ClientOnboardingEvent.properties.companyNext,
    );
  };

  const clientSizeInputProps = register("size", {
    // Immediately save the data when the option is changed
    onChange: saveData,
  });

  const workModeInputProps = register("workMode", {
    // Immediately save the data when the option is changed
    onChange: saveData,
  });

  return (
    <form name="companyInfo" onSubmit={handleSubmit(onSubmit)}>
      <Stack className="gap-4">
        <Flex className="flex-col">
          <div>
            <NativeSelect
              label={
                <Text className="mb-2 font-semibold">
                  What is your company’s primary industry?
                </Text>
              }
              {...register("industryId")}
              value={selectedIndustry}
              error={formState.errors.industryId?.message}
              classNames={{
                input: !selectedIndustry
                  ? "text-[color:--mantine-color-gray-5] mb-2"
                  : "mb-2",
              }}
              onChange={(event) => setSelectedIndustry(event.target.value)}
            >
              <option disabled value="">
                Please select
              </option>
              {!industryList ? (
                <option>Loading...</option>
              ) : (
                <>
                  {industryList?.map((industry) => (
                    <option key={industry.id} value={industry.id}>
                      {industry.name}
                    </option>
                  ))}
                </>
              )}
            </NativeSelect>
          </div>
          <div>
            <TextArea
              label={
                <Text className="mb-0 font-semibold">
                  What does your company do?
                </Text>
              }
              description={
                <Text
                  component="span"
                  className="mb-2 inline-block text-[color:--mantine-color-gray-7]"
                >
                  Write 1-2 sentences about your company’s primary activities to
                  help potential advisors understand your business.
                </Text>
              }
              inputWrapperOrder={["label", "description", "input", "error"]}
              minRows={4}
              autosize
              placeholder="Briefly explain your business"
              error={formState.errors.summary?.message}
              {...register("summary")}
            />
            <Text
              c={summaryLength <= SUMMARY_MAX_LENGTH ? "gray.6" : "red"}
              className="pt-1 text-xs"
            >
              {summaryLength}/{SUMMARY_MAX_LENGTH} characters
            </Text>
          </div>
          <label htmlFor={clientSizeInputProps.name}>
            <Text className="mb-2 font-semibold">
              How many employees work in your company?
            </Text>
            <SimpleGrid cols={{ base: 1, lg: 4 }}>
              {CLIENT_SIZE.map((size) => (
                <Radio
                  aria-label={size.label}
                  key={size.value}
                  label={size.label}
                  value={size.value}
                  {...clientSizeInputProps}
                />
              ))}
            </SimpleGrid>
            {formState.errors.size && (
              <Text c="red" className="mt-2 text-xs">
                {formState.errors.size.message}
              </Text>
            )}
          </label>
          <label htmlFor={workModeInputProps.name}>
            <Text className="mb-2 font-semibold">
              Do your employees work primarily onsite or remotely?
            </Text>
            <Stack>
              {WORK_MODE.map((workMode) => (
                <Radio
                  aria-label={workMode.label}
                  key={workMode.value}
                  label={workMode.label}
                  value={workMode.value}
                  {...workModeInputProps}
                />
              ))}
            </Stack>
            {formState.errors.workMode && (
              <Text c="red" className="mt-2 text-xs">
                {formState.errors.workMode.message}
              </Text>
            )}
          </label>
          {props.actionButtonGroup}
        </Flex>
      </Stack>
    </form>
  );
}

export function isCompanyInfoCompleted(user: User) {
  const { size, workMode, summary, industryId } =
    user?.clientProfile?.company || {};

  const parsed = schema.safeParse({
    size,
    workMode,
    summary,
    industryId,
  });
  return parsed.success;
}
