import {
  type UseQueryOptions,
  useQueries,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";

import type {
  SDocument,
  SDocumentPromptMetadata,
  Software,
  SoftwareCategory,
} from "types";

export const documentQueryKey = {
  document: "document",
  documentLinkMetadata: "documentLinkMetadata",
  publishedDocument: "publishedDoc",
  software: "softwareDocs",
  allSoftware: "allSoftwareDocs",
  bestPractices: "bestPracticesDocs",
  bestPracticesCategories: "bestPracticesCategories",
  userList: "userDocs",
};

export function useDocumentList({ isAdminView }: { isAdminView: boolean }) {
  return useQuery<SDocument[]>({
    queryKey: [documentQueryKey.userList, { isAdminView }],
    queryFn: async () => {
      const { data } = await axios.get("/documents", {
        params: {
          adminView: isAdminView,
        },
      });
      return data;
    },
  });
}

export function getDocumentByIdUrl(id?: string) {
  return `/documents/${id}`;
}

export function useDocument(id?: string) {
  return useQuery<SDocument>({
    queryKey: [documentQueryKey.document, id],
    queryFn: async () => {
      const { data } = await axios.get(getDocumentByIdUrl(id));
      return data;
    },
    enabled: !!id,
  });
}

export function usePublishedDocument(slug?: string) {
  return useQuery<SDocument>({
    queryKey: [documentQueryKey.publishedDocument, slug],
    queryFn: async () => {
      const { data } = await axios.get(`/document/publishes/${slug}`);
      return data;
    },
    enabled: !!slug,
  });
}

export type CategoryAndSoftwareDocs = SoftwareCategory & {
  softwares: Software[];
};

export function useSoftwareCategories({
  isAdminView,
}: {
  isAdminView: boolean;
}) {
  return useQuery<CategoryAndSoftwareDocs[]>({
    queryKey: [documentQueryKey.software, { isAdminView }],
    queryFn: async () => {
      const categoryType = isAdminView ? "admin" : "published";
      const { data } = await axios.get(`/software/categories/${categoryType}`);
      return data;
    },
  });
}

export function useAllSoftwareCategories() {
  return useQuery<SoftwareCategory[]>({
    queryKey: [documentQueryKey.allSoftware],
    queryFn: async () => {
      const { data } = await axios.get("/software/categories");
      return data;
    },
  });
}

export type BestPracticesCategory = {
  createdAt: string;
  updatedAt: string;
  description: string;
  name: string;
  status: "ACTIVE" | "PUBLISHED";
  publishedDocumentCount: number;
};

export type BestPracticesCategories = {
  totalPublishedDocumentCount: number;
  categories: BestPracticesCategory[];
};

export function useBestPracticesCategories({
  isAdminView,
}: {
  isAdminView: boolean;
}) {
  return useQuery<BestPracticesCategories>({
    queryKey: [documentQueryKey.bestPracticesCategories, { isAdminView }],
    queryFn: async () => {
      const categoryType = isAdminView ? "admin" : "published";
      const { data } = await axios.get(
        `/best-practice-topic/categories/${categoryType}`,
      );
      return data;
    },
  });
}

type UseBestPracticesDocOpts = {
  categoryName: BestPracticesCategory["name"];
};

type BestPracticesDocs = {
  id: string;
  title: string;
  documents: Pick<SDocument, "author" | "id" | "summary" | "urlTitle">[];
};

export function useBestPracticesDoc({ categoryName }: UseBestPracticesDocOpts) {
  return useQuery<BestPracticesDocs[]>({
    queryKey: [documentQueryKey.bestPractices],
    queryFn: async () => {
      const { data } = await axios.get(
        `/best-practice-topics/published?category=${categoryName}`,
      );
      return data;
    },
  });
}

export function getDocumentLinkMetadataUrl() {
  return "/documents/further-reading";
}

export function useDocumentLinkMetadata({ urlArray }: { urlArray: string[] }) {
  return useQueries({
    queries: urlArray.map<UseQueryOptions<SDocumentPromptMetadata>>((url) => ({
      queryKey: [documentQueryKey.documentLinkMetadata, url],
      queryFn: async () => {
        const { data } = await axios.post(getDocumentLinkMetadataUrl(), {
          url,
        });

        return data;
      },
      enabled: !!url,
      refetchOnWindowFocus: false, // Metadata does not need to be fetched every time
    })),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isPending: results.some((result) => result.isPending),
      isFetched: results.every((result) => result.isFetched),
    }),
  });
}
