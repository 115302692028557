import {
  IT_LEVELS,
  PRODUCTIVITY_SUITES,
} from "@/components/organisms/ClientOnboarding/ITSetupForm";
import type { ITLevel, ProductivitySuite } from "types";

type ClientITSetupProps = {
  itLevel?: ITLevel;
  productivitySuite?: ProductivitySuite;
  itSummary?: string;
  lookingFor?: string;
};

export function ClientITSetup({
  itLevel,
  productivitySuite,
  itSummary,
  lookingFor,
}: ClientITSetupProps) {
  return (
    <dl className="m-0">
      {itLevel && (
        <>
          <dt>Comfort level with IT</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {IT_LEVELS.find((s) => s.value === itLevel)?.label}
          </dd>
        </>
      )}

      {productivitySuite && (
        <>
          <dt>Productivity suite</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {
              PRODUCTIVITY_SUITES.find((s) => s.value === productivitySuite)
                ?.label
            }
          </dd>
        </>
      )}

      {itSummary && (
        <>
          <dt>How does your company currently manage IT?</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {itSummary}
          </dd>
        </>
      )}
      {lookingFor && (
        <>
          <dt>What is the primary reason you came to GuideStack?</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {lookingFor}
          </dd>
        </>
      )}
    </dl>
  );
}
