import {
  Experiment,
  type ExperimentClient,
  type ExperimentConfig,
} from "@amplitude/experiment-js-client";

class AmplitudeExperiments {
  private experimentClient: ExperimentClient;
  private variants: ExperimentClient | undefined;

  constructor(apiKey: string, options: ExperimentConfig) {
    this.experimentClient = Experiment.initializeWithAmplitudeAnalytics(
      apiKey,
      options,
    );
  }

  async init() {
    this.variants = await this.experimentClient.fetch();
  }

  clear() {
    this.variants?.clear();
  }

  getVariant(key: string) {
    if (!this.variants) {
      throw new Error("Experiments not initialized");
    }
    return this.variants.variant(key);
  }
}

const options = {
  // Currently Amplitude is configured to use local experiments,
  // so this is turned off.
  fetchOnStart: false,
  // Once identity changes (e.g. logging in or out), we want to fetch
  automaticFetchOnAmplitudeIdentityChange: true,
};

const amplitudeExperiments = new AmplitudeExperiments(
  import.meta.env.VITE_AMPLITUDE_API_KEY,
  options,
);

function useFeatureFlag(key: string) {
  const variant = amplitudeExperiments.getVariant(key);
  return {
    variant,
    get isOn(): boolean {
      return variant?.value === "on";
    },
  };
}

function clearFeatureFlags() {
  amplitudeExperiments.clear();
}

export { amplitudeExperiments, clearFeatureFlags, useFeatureFlag };
