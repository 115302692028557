import { SetExpertIndustries } from "@/third-party/amplitude";
import { isExpertProfileCompleted } from "@/utils/user-profile";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { Industry, SDocument, Software, User } from "types";
import { useAuth } from "./use-auth";
import { useDocumentList } from "./use-documents";
import { useExpertSoftware } from "./use-software";

export const expertQueryKey = {
  expert: "expert",
  expertSoftwares: "expert-softwares",
  expertDocuments: "expert-documents",
  expertIndustries: "expert-industries",
  expertsList: "experts-list",
};

export function expertByIdentifierUrl(identifier?: string) {
  return `/experts/${identifier}`;
}

export function useExpert({ identifier }: { identifier?: string }) {
  return useQuery<User>({
    queryKey: [expertQueryKey.expert, { identifier }],
    queryFn: async () => {
      const { data } = await axios.get(expertByIdentifierUrl(identifier));
      return data;
    },
    enabled: !!identifier,
  });
}

export function expertSoftwareSelectionByIdentifierUrl(identifier?: string) {
  return `/experts/${identifier}/softwares`;
}

export function useExpertSoftwareSelection({
  identifier,
}: {
  identifier?: string;
}) {
  return useQuery<Software[]>({
    queryKey: [expertQueryKey.expertSoftwares, { identifier }],
    queryFn: async () => {
      const { data } = await axios.get(
        expertSoftwareSelectionByIdentifierUrl(identifier),
      );
      return data;
    },
    enabled: !!identifier,
  });
}

export function expertDocumentsByIdentifierUrl(identifier?: string) {
  return `/experts/${identifier}/documents`;
}

export function useExpertDocuments({ identifier }: { identifier?: string }) {
  return useQuery<SDocument[]>({
    queryKey: [expertQueryKey.expertDocuments, { identifier }],
    queryFn: async () => {
      const { data } = await axios.get(
        expertDocumentsByIdentifierUrl(identifier),
      );
      return data;
    },
    enabled: !!identifier,
  });
}

export function expertsListUrl() {
  return "/experts";
}

export function useExpertsList({
  includeNewJoiner,
}: {
  includeNewJoiner: boolean;
}) {
  return useQuery<User[]>({
    queryKey: [expertQueryKey.expertsList, { includeNewJoiner }],
    queryFn: async () => {
      const { data } = await axios.get(expertsListUrl(), {
        params: { includeNewJoiner },
      });
      return data;
    },
  });
}

export function expertIndustriesUrl() {
  return "/expert/industries";
}

export function useExpertIndustries() {
  return useQuery<Industry[]>({
    queryKey: [expertQueryKey.expertIndustries],
    queryFn: async () => {
      const { data } = await axios.get<Industry[]>(expertIndustriesUrl());
      SetExpertIndustries(data.map((industry) => industry.name));
      return data;
    },
  });
}

export function useExpertReadiness() {
  const { user, role } = useAuth();
  const isProfileSetupDone = isExpertProfileCompleted(user);
  const { data: softwares } = useExpertSoftware();
  const { data: industries } = useExpertIndustries();
  const { data: documents } = useDocumentList({
    isAdminView: role === "admin",
  });
  const hasSoftware = Boolean(softwares?.length);
  const hasIndustry = Boolean(industries?.length);

  // Admins can see all documents, but expert should only be able to see their own
  const hasDocument = Boolean(
    documents?.find((document) => document.author?.id === user?.id),
  );

  const hasPublishedDocument = Boolean(
    documents?.find((document) => document.status === "PUBLISHED"),
  );

  return {
    hasSoftware,
    hasIndustry,
    hasDocument,
    hasPublishedDocument,
    isProfileSetupDone,
    isApproved: user?.expertProfile?.status === "APPROVED",
    isPayoutOnboarded: Boolean(user?.expertProfile?.payoutOnboarded),
    isBookingOnboarded: Boolean(user?.expertProfile?.calendlyConfigured),
  };
}
