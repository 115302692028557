import { Logo } from "@/components/atoms/Logo/Logo";
import { paths } from "@/routes/paths";

export function FullPageError() {
  return (
    <div className="h-screen bg-[#FCFCFD]">
      <header className="flex h-[4.5rem] items-center bg-white px-4 lg:shadow-paper">
        <Logo />
      </header>
      <main className="mx-auto max-w-4xl pt-6">
        <div className="flex flex-col gap-4 bg-white p-6">
          <p className="m-0 font-semibold text-2xl">Something went wrong.</p>
          <p className="m-0 text-[#4A5565]">
            Sorry, we are working hard to fix GuideStack for you.
          </p>
          <div className="flex gap-4">
            <button
              type="button"
              onClick={() => {
                window.location.reload();
              }}
              className="w-fit rounded border border-[#6941C6] border-solid bg-white px-4 py-2 text-[#6941C6] no-underline hover:cursor-pointer hover:bg-[#f7f5fd]"
            >
              Refresh the page
            </button>
            <a
              className="inline-block w-fit rounded px-4 py-2 text-[#6941C6] no-underline hover:bg-[#f7f5fd]"
              href={paths.documentation.root}
            >
              Back to the dashboard
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}
