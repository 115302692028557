import type { LikeNotification } from "types";
import { NotificationItem } from "./NotificationItem";

export function Like({ notification }: { notification: LikeNotification }) {
  const isTopic = Boolean(notification.document.topic);

  const actionText = isTopic
    ? "Liked your document ·"
    : "Liked your document about";

  return (
    <NotificationItem
      notification={notification}
      type="like"
      actionText={actionText}
    />
  );
}
