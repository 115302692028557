import { clsx } from "clsx/lite";

import LogoImage from "@/assets/logos/guidestack.svg";

interface Props {
  className?: string;
}

const Logo = (props: Props) => (
  <img
    src={LogoImage}
    alt="GuideStack"
    className={clsx("max-h-[1.375rem]", props.className)}
  />
);

export { Logo };
