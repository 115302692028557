import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { httpClient } from "@/api/client";
import { SetClientSoftware, SetExpertSoftware } from "@/third-party/amplitude";
import type { Software } from "types";

type UseSoftwareQueryParams = {
  includeDocuments?: boolean;
  softwareIds?: string;
};

export function getSoftwaresUrl() {
  return "/v1/softwares";
}

export function useSoftware(queryParams: UseSoftwareQueryParams = {}) {
  return useQuery({
    queryKey: ["softwares", queryParams],
    queryFn: async () => {
      const { data } = await httpClient.paths["/v1/softwares"].get(queryParams);
      return data.sort((nameA, nameB) => nameA.name.localeCompare(nameB.name));
    },
  });
}

const clientSoftwareQueryKey = ["clientSoftwares"];
export function useClientSoftware() {
  return useQuery({
    queryKey: clientSoftwareQueryKey,
    queryFn: async () => {
      const { data } = await httpClient.paths["/v1/client/softwares"].get();
      SetClientSoftware(data.map((software) => software.name));
      return data;
    },
  });
}

const expertSoftwareQueryKey = ["expertSoftwares"];
export function useExpertSoftware() {
  return useQuery<Software[]>({
    queryKey: expertSoftwareQueryKey,
    queryFn: async () => {
      const { data } = await axios.get<Software[]>("/expert/softwares");
      SetExpertSoftware(data.map((software) => software.name));
      return data;
    },
  });
}

export function useSoftwareMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: {
      softwareId: string;
      for: "client" | "expert";
      action: "put" | "delete";
    }) => {
      const request = axios[variables.action];

      return request(`/${variables.for}/softwares/${variables.softwareId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: clientSoftwareQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: expertSoftwareQueryKey,
      });
    },
    onError: () => {
      //TODO: log error and show notification
    },
  });
}
