import { Avatar, Button, Group } from "@flpstudio/design-system";
import axios, { type AxiosError } from "axios";
import { useRef, useState } from "react";

import { useAuth } from "@/hooks/use-auth";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import {
  Amplitude,
  ClientOnboardingEvent,
  ExpertOnboardingEvent,
} from "@/third-party/amplitude";

export function UserPhotoForm() {
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();
  const [uploadLoading, setUploadLoading] = useState(false);
  const imageInputRef = useRef<HTMLInputElement>(null);

  return (
    <form>
      <Group>
        <Avatar
          className="size-16"
          src={user?.profileImage}
          color={user?.randomProfileColor}
          autoContrast={true}
        >
          {user?.name?.[0]?.toUpperCase() || ""}
        </Avatar>
        <Button
          variant="outline"
          loading={uploadLoading}
          onClick={() => {
            imageInputRef.current?.click();
            if (user?.clientProfile) {
              Amplitude.track(
                ClientOnboardingEvent.name,
                ClientOnboardingEvent.properties.uploadPhoto,
              );
            } else {
              Amplitude.track(
                ExpertOnboardingEvent.name,
                ExpertOnboardingEvent.properties.uploadPhoto,
              );
            }
          }}
        >
          Upload photo
        </Button>
        <input
          data-testid="uploadInput"
          hidden
          accept="image/*"
          ref={imageInputRef}
          type="file"
          onChange={(e) => {
            if (e.target.files?.[0]) {
              const file = e.target.files[0];
              setUploadLoading(true);
              uploadProfileImage(file, (progress) => {
                console.log(progress);
              })
                .then((result) => {
                  updateUser({ profileImage: result });
                })
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => {
                  setUploadLoading(false);
                });
            }
          }}
        />
      </Group>
    </form>
  );
}

type PresignedPostResponse = {
  result: { size96: string };
  url: string;
  fields: Record<string, string>;
};
async function uploadProfileImage(file: File, progress: (val: number) => void) {
  try {
    const { data: preSignedPostData } = await axios.post<PresignedPostResponse>(
      "/media/upload/presigned-post",
      {
        filename: file.name,
      },
    );

    const formData = new FormData();
    for (const key in preSignedPostData.fields) {
      const val = preSignedPostData.fields[key];
      if (val) {
        formData.append(key, val);
      }
    }

    formData.append("file", file);

    const { status } = await axios.post(preSignedPostData.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: null, // Signed URL does not accept Authorization header
      },
      onUploadProgress: (e) => {
        if (!e.total) return;
        const val = (e.loaded / e.total) * 100;
        progress(val);
      },
      withCredentials: false,
    });

    if (status === 204) {
      console.log(preSignedPostData.result);
    }

    return preSignedPostData.result.size96;
  } catch (error) {
    const errorObj = error as AxiosError;
    throw new Error(errorObj.message);
  }
}
