import { Button } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";
import { clsx } from "clsx/lite";
import { useEffect, useState } from "react";

import { useAuth } from "@/hooks/use-auth";
import {
  useAvailableAdvisorySessionBooking,
  useAvailableIntroBooking,
  useRemoveAvailableIntroBooking,
} from "@/hooks/use-booking";
import { useBookingSearchParams } from "@/hooks/use-page-search-params";
import { formatMoney } from "@/utils/format-money";
import { AddPaymentMethodDialog } from "./AddPaymentMethodDialog";
import { CalendlyAddBookingDialog } from "./CalendlyAddBookingDialog";
import { EnterVoucherDialog } from "./EnterVoucherDialog";
import { MaxBookingsDialog } from "./MaxBookingsDialog";
import { UnpaidBookingDialog } from "./UnpaidBookingDialog";

type BookingType = "INTRO_BOOKING" | "ADVISORY_BOOKING";

const INTRO_BOOKING = "INTRO_BOOKING";
const ADVISORY_BOOKING = "ADVISORY_BOOKING";

type Props = {
  className?: string;
  expertIdentifier: string;
  /**
   * Initiate booking on when `bookingSession` search param is present
   */
  initiateOnSearchParams?: boolean;
  /**
   * Display "rebook" button instead of the "booking" button
   */
  isRebooking?: boolean;
  onBookSessionClick?: (bookingType: BookingType) => void;
};

export function Booking(props: Props) {
  const { user } = useAuth();

  const { isStartBooking, clearStartBooking, generateStartBookingLink } =
    useBookingSearchParams();

  const [addPaymentMethodDialogOpened, addPaymentMethodDialogHandlers] =
    useDisclosure();
  const [enterVoucherDialogOpened, enterVoucherDialogHandlers] =
    useDisclosure();
  const [maxBookingsDialogOpened, maxBookingsDialogHandlers] = useDisclosure();
  const [unpaidBookingDialogOpened, unpaidBookingDialogHandlers] =
    useDisclosure();

  const [sessionInfo, setSessionInfo] = useState<{
    calendlySchedulingUrl: string;
    bookingType: BookingType | "";
  }>({
    calendlySchedulingUrl: "",
    bookingType: INTRO_BOOKING,
  });

  const isSelf = props.expertIdentifier === (user?.urlId || user?.id);

  const removeAvailableIntroBooking = useRemoveAvailableIntroBooking({
    identifier: props.expertIdentifier,
  });

  // Standard bookings
  const { data, refetch } = useAvailableAdvisorySessionBooking({
    identifier: isSelf ? "" : props.expertIdentifier,
  });

  // Auto open calendly dialog if start booking param is present
  useEffect(() => {
    if (
      props.initiateOnSearchParams &&
      isStartBooking &&
      data?.calendlySchedulingUrl &&
      // Free sessions take precedence over existence of payment method
      (availableFreeSessions || paymentAdded)
    ) {
      setSessionInfo({
        calendlySchedulingUrl,
        bookingType: ADVISORY_BOOKING,
      });
    }
  }, [props.initiateOnSearchParams, isStartBooking, data]);

  // Render button only if booking data is available
  if (!data) {
    return null;
  }

  const {
    availableFreeSessions,
    unpaidInvoiceLink,
    paymentAdded,
    calendlySchedulingUrl,
    reachedMaxUpcomingSessions,
    price,
    durationMinute,
  } = data;

  const initiateAdvisoryBooking = () => {
    props.onBookSessionClick?.(ADVISORY_BOOKING);

    if (!availableFreeSessions && unpaidInvoiceLink) {
      unpaidBookingDialogHandlers.open();
      return;
    }

    if (!availableFreeSessions && !paymentAdded) {
      addPaymentMethodDialogHandlers.open();
      return;
    }

    if (reachedMaxUpcomingSessions) {
      maxBookingsDialogHandlers.open();
      return;
    }

    setSessionInfo({
      calendlySchedulingUrl,
      bookingType: ADVISORY_BOOKING,
    });
  };

  return (
    <div className={clsx("flex items-stretch gap-2", props.className)}>
      {props.isRebooking ? (
        <Button variant="outline" onClick={initiateAdvisoryBooking}>
          Book again
        </Button>
      ) : (
        <>
          <IntroductorySessionBooking
            expertIdentifier={
              props.isRebooking || isSelf ? "" : props.expertIdentifier
            }
            onClick={(calendlySchedulingUrl) => {
              props.onBookSessionClick?.(INTRO_BOOKING);
              setSessionInfo({
                calendlySchedulingUrl,
                bookingType: INTRO_BOOKING,
              });
            }}
            className="h-auto"
          />
          <Button
            className="h-fit px-4 py-2"
            classNames={{ label: "flex-col font-normal" }}
            onClick={initiateAdvisoryBooking}
          >
            <span>Book a session</span>
            {availableFreeSessions ? (
              <span className="text-xs/normal">
                {availableFreeSessions} free credit
                {availableFreeSessions > 1 ? "s" : ""} available
              </span>
            ) : (
              <span className="text-xs/normal">
                {formatMoney({
                  amount: price.amount,
                  currency: price.currency,
                  showDecimal: false,
                })}{" "}
                for {durationMinute} min
              </span>
            )}
          </Button>
        </>
      )}
      {!paymentAdded && (
        <AddPaymentMethodDialog
          opened={addPaymentMethodDialogOpened}
          onUseVoucherCode={() => {
            addPaymentMethodDialogHandlers.close();
            // Wrapping it in setTimeout so focus moves to the enterVoucherDialog
            setTimeout(enterVoucherDialogHandlers.open);
          }}
          onCancel={addPaymentMethodDialogHandlers.close}
          cancelCallbackUrl={window.location.href}
          successCallbackUrl={generateStartBookingLink(window.location.href)}
        />
      )}
      <EnterVoucherDialog
        opened={enterVoucherDialogOpened}
        expertIdentifier={props.expertIdentifier}
        onBack={() => {
          enterVoucherDialogHandlers.close();
          // Wrapping it in setTimeout so focus moves to the addPaymentMethodDialog
          setTimeout(addPaymentMethodDialogHandlers.open);
        }}
        onClose={enterVoucherDialogHandlers.close}
        onContinue={() => {
          enterVoucherDialogHandlers.close();
          initiateAdvisoryBooking();
        }}
      />
      {reachedMaxUpcomingSessions && (
        <MaxBookingsDialog
          opened={maxBookingsDialogOpened}
          onCancel={maxBookingsDialogHandlers.close}
        />
      )}
      {unpaidInvoiceLink && (
        <UnpaidBookingDialog
          invoiceLink={unpaidInvoiceLink}
          opened={unpaidBookingDialogOpened}
          onCancel={unpaidBookingDialogHandlers.close}
        />
      )}
      <CalendlyAddBookingDialog
        opened={!!sessionInfo.calendlySchedulingUrl}
        expertIdentifier={props.expertIdentifier}
        calendlySchedulingUrl={sessionInfo.calendlySchedulingUrl}
        bookingType={sessionInfo.bookingType || INTRO_BOOKING}
        onConfirm={() => {
          if (sessionInfo.bookingType === INTRO_BOOKING) {
            // There can only be one AvailableIntroBooking per expert
            // So we remove the query cache after an Introductory session is booked
            removeAvailableIntroBooking();
          } else {
            refetch();
          }
        }}
        onClose={() => {
          clearStartBooking();
          setSessionInfo(() => ({
            calendlySchedulingUrl: "",
            bookingType: "",
          }));
        }}
        isVoucherCodeUsed={!!availableFreeSessions}
      />
    </div>
  );
}

type IntroductorySessionBookingProps = {
  className?: string;
  expertIdentifier: string;
  onClick?: (calendlySchedulingUrl: string) => void;
};

function IntroductorySessionBooking(props: IntroductorySessionBookingProps) {
  const { data } = useAvailableIntroBooking({
    identifier: props.expertIdentifier,
  });

  if (!data) {
    return null;
  }

  return (
    <Button
      variant="outline"
      className={props.className}
      onClick={() => props.onClick?.(data.calendlySchedulingUrl)}
    >
      Book a <br /> free intro
    </Button>
  );
}
